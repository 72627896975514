import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const quizProgrssSlice = createSlice({
	name: "Quiz Progress",
	initialState: {
		value: [],
	},
	reducers: {
		emptyQuiz: (state, action) => {
			state.value = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getQuizProgress.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const getQuizProgress = createAsyncThunk(
	"/getQuizProgress",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			
			const ids = args.map((id) => {
				return axios.post(
					`${url}/getStudentProgress`,
					{ StudentProgressId: id.progressId },
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
			});
			const data = await Promise.allSettled(ids);
			// console.log(data);
			return data.map((e) => e.value.data);
		} catch (error) {
			console.log("error in addQuizProgress", error.message);
		}
	}
);

export default quizProgrssSlice;
