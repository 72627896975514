import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const allQuizSlice = createSlice({
	name: "All Quizes",
	initialState: {
		value: [],
	},
	extraReducers: (builder) => {
		builder.addCase(getAllQuiz.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const getAllQuiz = createAsyncThunk("/getAllQuiz", async (args) => {
	try {
		const token = localStorage.getItem("token");
		const courseName = args.courseName;

		const res = await axios.post(
			`${url}/getCourseQuizes`,
			{ courseName },
			{
				headers: {
					Authorization: "Bearer " + token,
				},
			}
		);
		const data = await Promise.allSettled([res]);
		return data[0].value.data;
	} catch (error) {
		return new Error(error);
	}
});

export default allQuizSlice;
