import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
// import { getStudentQuery } from "../../graphql/student";
import Axios from "axios";
import { url } from "../../graphql";

const studenttSlice = new createSlice({
	name: "student",
	initialState: {
		value: [],
		pass:""
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(updateStudent.fulfilled, (state, action) => {
			// Add user to the state array
			state.value = action.payload;
		});
		builder.addCase(AddOtp.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(forgotPasswordFun.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(AddOtp.rejected, (state, action) => {
			state.value = "";
		});
		builder.addCase(OtpLogin.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(OtpLogin.rejected, (state, action) => {
			state.value = "";
		});
	},
});

export const updateStudent = createAsyncThunk(
	"/updateStudent",
	async (action) => {
		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");
		const response = await Axios.post(
			`${url}/updateStudent`,
			id ? { userId: id, browser: "" } : action,
			{
				headers: {
					Authorization: "Bearer " + token,
				},
			}
		);
		const data = response.data.data.updateStudent;

		return data;
	}
);

export const forgotPasswordFun = createAsyncThunk(
	"/forgotPassword",
	async (args) => {
		const token = localStorage.getItem("token");

		const response = await Axios.post(
			`${url}/forgotPassword`,
			{
				email:args.email,
				password:args.password
			},
			{
				headers: {
					Authorization: "Bearer " + token,
				},
			}
		);
		const data = response.data.data.updateStudent;

		return data;
	}
);

export const updateProfilePic = createAsyncThunk(
	"/updateProfilePic",
	async (action) => {
		const token = localStorage.getItem("token");
		const response = await Axios.post(
			url,
			{
				variables: {
					url: action.payload.url,
					userType: action.payload.userType,
				},
			},
			{
				headers: {
					Authorization: "Bearer " + token,
				},
			}
		);

		return response.data.data.students;
	}
);

export const AddOtp = createAsyncThunk("/getOtp", async (action) => {
	console.log(action);
	const response = await Axios.post(`${url}/otpAdd`, {
		email: action.email,
		phone: action.phone,
	});
	console.log(action);

	return response;
});
export const OtpLogin = createAsyncThunk("otpLogin", async (action) => {
	try {
		console.log(action);
		const response = await Axios.post(`${url}/Otplogin`, {
			email: action.email,
			phone: action.phone,
			otp: action.otp,
			browser: action.browser,
		});
		console.log(response);

		if (response.data.errors) return response.data.errors[0];

		let token = response.data.token;
		let userId = response.data.userID;

		localStorage.setItem("token", token);
		localStorage.setItem("id", userId);

		//   Axios.defaults.headers.common["Authorization"] =  token;
		// const data = response.data;
		// window.location.reload();
		return response;
	} catch (error) {
		console.log(error);
	}
});
export default studenttSlice;
