import { CalendarOutlined } from "@ant-design/icons/lib/icons";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import React, { useEffect, useRef, useState } from "react";
import tbodystyles from "../styles/tBody.module.scss";
import Slider from "react-slick";
import certificate from "../media/Techie pand certification.jpg";
import tick from "../icons/tick.png";
import lock from "../icons/lock.png";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../redux/slices/section";
import lockYellow from "../icons/lock_yellow.png";
import { addSection } from "../redux/slices/SelectedSection";
import unlockGold from "../media/unlocked.png";
import { message } from "antd";
import {
	getStudents,
	updateStudentProgressForDay,
	updateStudentProgressForDayTelugu,
} from "../redux/slices/student";
import { useCallback } from "react";
import { getAllQuiz } from "../redux/slices/getallQuizesSlices";
import { getProject } from "../redux/slices/projectsSlice";
import { fetchAllAssignments } from "../redux/slices/assignmentSlice";
import LoadingScreen from "./LoadingScreen";
import { getSectionTitle } from "../redux/slices/sectionTite";
import { getQuizProgress } from "../redux/slices/quizProgressSlice";
import Meeting from "../Utils/meeting";

export default function TimeTableBody({
	openCal,
	setOpenCalender,
	select,
	setSelected,
	selectedDate,
	setSelectedDate,
}) {
	const dispatch = useDispatch();
	let authToken = localStorage.getItem("token");
	const studentId = localStorage.getItem("id")
	let day = localStorage.getItem("day");
	const navigate = useNavigate();
	const sectionTitle = useSelector((state) => state.SectionTitle.value);
	const [st, setSt] = useState({});
	const section = useSelector((state) => state.sections.value);
	const projects = useSelector((state) => state.project.value);
	const [setArr, setSetArr] = useState([]);
	const savedProgress = useSelector((state) => state.videoProgress.value);
	const student = useSelector((state) => state.student.value);
	const [disabledDays, setDisabledDays] = useState();
	const tickRef = useRef([]);
	const selectedCourse = useSelector((state) => state.course.selectedCourse);
	const allQuizes = useSelector((state) => state.allQuizes.value);
	const courseEnrolledDate = selectedCourse?.createdAt;
	const SectTitle = localStorage.getItem("StTitle")
	const [btnTitles, setBtnTitles] = useState([]);
	const loadingState = useSelector((state) => state.sections.state);
	const [messageApi, contextHolder] = message.useMessage();
	const [latestDay,setLatestDay] = useState("")
	const module = useSelector((state) => state.module.oneModule);
    const modules = useSelector((state)=>state.module.value)

	const EnrolledData = student?.courseEnrolledData? student.courseEnrolledData : null

	const FilterCourse = EnrolledData?.find((e)=>e.courseId == selectedCourse._id )

	const allAssignments = useSelector(
		(state) => state.assignment.allAssignments
	);
	const sectionTitleStatus = useSelector((state) => state.SectionTitle.status);
	const language = useSelector((state) => state.language.value);

	const navigation = useCallback(() => {
		if (!authToken && authToken === "undefined") {
			messageApi.open({
				type: "error",
				content: "Not Authorised",
			});
			navigate("/", { replace: true });
		}

	}, [navigate, authToken]);


	useEffect(() => {
		navigation();
	}, [navigation]);

	useEffect(() => {
		if (!day|| day == "undefined" || day == "null" || day =="" || day == "NaN") {
			if (student && student?.SectionTitleProgress &&student?.SectionTitleProgress?.length) {
				localStorage.setItem(
					"day",
					`${
						student.SectionTitleProgress[
							student.SectionTitleProgress.length - 1
						].Day + 1
					}`
				);
			} else localStorage.setItem("day", "1");
		} else {
			const stt =sectionTitle&& sectionTitle?.find((e) => e.Day === day );
			if (stt) {
				setSt(stt);
			}
			
		}
	}, [sectionTitle?.length, day, student?.SectionTitleProgress,day,]);



	window.onclick = (event) => {
		if (!event.target.closest(".daypick")) {
			setOpenCalender(false);
		}
	};



	const settings = {
		dots: false,
		infinite: true,
		slidesToShow: sectionTitle?.length >= 9 ? 9 : sectionTitle?.length - 1,
		// slidesToShow: 9,
		slidesToScroll: 4,
		className: tbodystyles.slic,
		responsive: [
			{
				breakpoint: 1200,
				settings: {
					slidesToShow: 6,
					slidesToScroll: 1,
					infinite: true,
					dots: false,
				},
			},
			{
				breakpoint: 600,
				settings: {
					slidesToShow: 4,
					slidesToScroll: 1,
				},
			},
			{
				breakpoint: 480,
				settings: {
					slidesToShow: 2,
					slidesToScroll: 1,
				},
			},
			// You can unslick at a given breakpoint now by adding:
			// settings: "unslick"
			// instead of a settings object
		],
	};

	useEffect(() => {
		let darArr = [];
		sectionTitle &&
			sectionTitle.forEach((e) => {
				if (e.Day !== undefined) {
					return darArr.push(e.Day);
				}
			});
		let sortdayArr = darArr.sort((a, b) => a - b);
		const set = new Set(sortdayArr);
		setSetArr(Array.from(set));
	}, [sectionTitle, student]);

	useEffect(() => {
		if (st.projects && st.projects.length) {
			setBtnTitles(projects);
		} else {
			setBtnTitles(section);
		}
	}, [st, section, projects]);

	const openCalender = () => {
		setOpenCalender(!openCal);

		if (selectedCourse) {
			Date.prototype.addDays = function (days) {
				var date = new Date(this.valueOf());
				date.setDate(date.getDate() + days);
				return date;
			};
			if (courseEnrolledDate || FilterCourse.courseDate) {
				const today = new Date(FilterCourse.courseDate?FilterCourse.courseDate:courseEnrolledDate) ;
				
				setSelected(select);
				const startDate = new Date(FilterCourse.courseDate?FilterCourse.courseDate:courseEnrolledDate);
				let copy = new Date(startDate.valueOf());
				const disabledDays = [
					{
						from: new Date(2000, 4, 18),
						to: new Date(copy.setDate(copy.getDate() - 1)),
					},
					{
						from: startDate.addDays(setArr.length),
						to: new Date(2030, 11, 1),
					},
				];

				setDisabledDays(disabledDays);
			}
		}
	};
	const selected = (event, selDay) => {

		localStorage.setItem("day", selDay);
		sectionTitle?.forEach((e) => {

			if (e.Day === selDay) {
				setSt(e);
				if(e.Section) dispatch(getSection(e.Section));
			}
		});
		dispatch(getStudents());
		// console.log(selDay);
	};

	// useEffect(()=>{
	// 	if(sectionTitle && sectionTitle[0]){
	// 		dispatch(getSection(sectionTitle[0].Section))
	// 	}
	// },[sectionTitle])

	const DaysBetween = useCallback(
		(EndDate) => {
			// The number of milliseconds in all UTC days (no DST)
			const StartDate = new Date(FilterCourse.courseDate?FilterCourse.courseDate : courseEnrolledDate);
			const oneDay = 1000 * 60 * 60 * 24;

			// A day in UTC always lasts 24 hours (unlike in other time formats)
			const start = Date.UTC(
				EndDate.getFullYear(),
				EndDate.getMonth(),
				EndDate.getDate()
			);
			const end = Date.UTC(
				StartDate.getFullYear(),
				StartDate.getMonth(),
				StartDate.getDate()
			);

			// so it's safe to divide by 24 hours
			const days = (start - end) / oneDay;
			let newDays = days + 1;
				
			setSelectedDate(newDays);
			return newDays;
		},
		[courseEnrolledDate]
	);

	useEffect(() => {
		if (openCal) {
			if (select) {

				DaysBetween(select);
			}
		}
	}, [openCal, select]);

	useEffect(() => {
		if (openCal) {
			if (selectedDate && selectedDate <= setArr?.length) {
				sectionTitle?.forEach((e) => {
					if (+e.Day === selectedDate) {

						setSt(e);
						localStorage.setItem("day",st.Day)
					}
				});
			}
		}
		localStorage.setItem("StTitle",st?.title)
	}, [selectedDate, openCal,select,st,st?.title]);

	const [b, setb] = useState();

	const openNextSection = () => {
		const currentRef = tickRef?.current;
		const newRefs = currentRef?.filter((e) => {
			return e && e;
		});

		const filter = newRefs?.filter((e) => e.getAttribute("src") === `${tick}`);


		if (student && student?.SectionTitleProgress) {
			if (
				newRefs?.length !== filter?.length &&
				+st.Day ==
					+student?.SectionTitleProgress[
						student?.SectionTitleProgress?.length - 1
					]?.Day  +1
			) {
				newRefs[filter.length].setAttribute("src", unlockGold);
				newRefs[filter.length].parentNode.parentNode.disabled = false;
				newRefs[filter.length].setAttribute("width", "60%");
				newRefs[filter.length].setAttribute("height", "60%");
				newRefs[filter.length].parentNode.parentNode.parentNode.onclick = (
					event
				) => {
					event.preventDefault();
					navigate("/dashboard", { replace: true });
					localStorage.setItem(
						"topic",
						newRefs[filter.length].parentNode.parentNode.innerText
					);
					localStorage.setItem("index", filter.length);
					const newFilterSection = section?.find(
						(e) =>
							e.title === newRefs[filter.length].parentNode.parentNode.innerText
					);
					dispatch(
						addSection({ value: newFilterSection, index: filter.length })
					);
				};
			}
		}
	};

	useEffect(() => {
		const sectionTitleProgress = (section) => {
			if (!st.projects) {
				let a = [];
				if (section) {
					for (let i of section) {
						a?.push(
							savedProgress?.find((e) => {
								return e.courseId === selectedCourse?._id
									? language === "Telugu"
										? e.videoId === i.teluguVideoId
										: e.videoId === i.videoId
									: null;
							})
						);
					}

					if (a.length) {
						a.forEach((e) => {
							if (e) {
								const bb = a?.filter((f) => (f ? f.progress > 80 : null)).length;


								setb(bb);
							}
						});
					}

					// console.log(b);
					// console.log(section.length);
				}
			}
		};
		sectionTitleProgress(section);

		openNextSection();
	}, [section, savedProgress, language]);


	useEffect(() => {
		let isCompleted = false;
		if (student)

			if (section.length === b && st && !st?.projects) {
				isCompleted = true;
				if (
					isCompleted
				) {
					const courseId = selectedCourse._id;
				
				} else {
			
				messageApi.open({
						type: "error",
						content:
							"Go in a order of sections for better understanding of concepts".toUpperCase(),
					});
				
				}
			}

		return () => {
			if (isCompleted) {
				isCompleted = false;
			}
		};
	}, [b]);

	useEffect(() => {
	if(st)	if (!st.projects) if (st.Section) dispatch(getSection(st.Section));
	}, [st]);

	const quizLengthforDay = () => {
		let len = [];
		let a = true;
		if (section.length) {
			for (let i of section) {
				if (a) {
					if (i.quiz) {
						len.push(i.quiz.length);
					} else {
						a = false;
					}
				}
			}
		}
		if (len.length) {
			const totallength = len?.reduce((a, b) => a + b);
			return totallength;
		} else {
			return 0;
		}
	};

	const assignmentsLengthForDay = () => {
		const filter = section ? section?.filter((e) => e.Assignment).length : 0;
		return filter;
	};

	function calculateAllLessons() {
		if (sectionTitle&&sectionTitle.length) {
			let a = [];

			sectionTitle&&sectionTitle.forEach((e) => {
				if (+e.Day) {
					a.push({
						day: +e.Day,
						data: e,
					});
				}
			});
			const removeDuplicateValues = a?.filter(
				(e, i) => i === a.findIndex((t) => t.day === e.day)
			);

			// completedlessons(removeDuplicateValues);

			const calc = removeDuplicateValues && removeDuplicateValues?.map((e) =>
				e ? (e.data ? (e.data.Section ? e.data.Section.length : 0) : 0) : 0
			);
				
			const c = calc.reduce((a, b) => a + b);
			return c;
		}
	}

	const completedLessons = () => {
		return savedProgress?.filter((e) => {
			return e.courseId === selectedCourse?._id && e?.progress >= 80;
		}).length;
	};

	const quizprogress = useSelector((state) => state.quizProgress?.value);

	const quizProgressFun = useCallback(() => {
		if (quizprogress?.length) {
			const a = quizprogress?.filter((e) => {
				return e.progress?.courseId === selectedCourse?._id && e;
			})?.length;
			return a;
		}
	}, [quizprogress]);

	useEffect(() => {
		if (selectedCourse.course_name) {
			dispatch(getAllQuiz({ courseName: selectedCourse?.course_name }));
		}
	}, [selectedCourse.course_name, dispatch]);

	useEffect(() => {
		if (st.projects && st.projects.length) {
			dispatch(getProject(st.projects));
		}
	}, [st.projects, dispatch]);

	useEffect(() => {
		localStorage.setItem("day",1)
		dispatch(fetchAllAssignments());
	}, []);


	return (
		<>
			{contextHolder}
			<div className={tbodystyles.tbContainer}>
				{sectionTitleStatus ? (
					<div
						style={{
							width: "100%",
							height: "100vh",
							flex: 1,
							display: "grid",
							placeItems: "center",
						}}>
						<LoadingScreen />
					</div>
				) : (
					<div className={tbodystyles.flex}>
						<div className={tbodystyles.left}>
							<div className={tbodystyles.head}>
								<div className={tbodystyles.schedule}>
									Your Schedule
									<br />
									<span>Today</span>
								</div>
								<div className={`${tbodystyles.calendar} daypick`}>
								
								</div>
							</div>
							<div className={tbodystyles.carousel}>
								<Slider {...settings}>
									{setArr &&
										setArr.map((e, index) => {

											const completedTime = student?.SectionTitleProgress?.find((l) => l?.sectionTitleId === sectionTitle[index > 0 ? index -1 : 0]?._id)?.CompletedTime;

											const completedDate = completedTime && new Date(completedTime).toISOString()?.split('T')[0]?.split("-") ;
											const todayDate = new Date()?.toISOString()?.split('T')[0]?.split("-");
										 
											const completedToNum = completedDate && todayDate && completedDate[0] + completedDate[1] + completedDate[2] < todayDate[0]+todayDate[1] + todayDate[2] 


											const isCompletedToday = completedToNum
											let unlock;
											if (student?.SectionTitleProgress) {
												unlock =
													index == 0 ? student?.SectionTitleProgress?.find((l)=> l?.sectionTitleId == sectionTitle[index > 0 ?index -1 :0]?._id)? tick : 
													unlockGold
												 :lock
											} else {
												unlock = lock;
											}
											return (
												<div key={index}>
													<button
														disabled={ index == 0 ? false :
															studentId == "6513e2a7dc706cf1e7a23b0a"||studentId === "633d8c8e8f3bcd7e94612929"|| studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b" ? false : isCompletedToday == false  ? true :	student?.SectionTitleProgress?.find((l)=> l?.sectionTitleId == sectionTitle[index]?._id) ? false : student?.SectionTitleProgress?.find(
																	(f) => (f.sectionTitleId == sectionTitle[ index -1 ]?._id)
															  ) ? false 
																: true	
														}
														className={tbodystyles.h3}
														onClick={(ef) => {
															selected(ef, e);
														}}>
														<div
															className={`${tbodystyles.h3} change`}
															style={
																e === localStorage.getItem("day")
																	? {
																			boxShadow: "0px 0px 5px 3px #f4b34a6c",
																			border: "1px solid #f4b34a6c",
																	  }
																	: null
															}>
															{e}{" "}

														</div>
														<img
															width="30%"
															src={
																student?.SectionTitleProgress?.find((l)=> l?.sectionTitleId == sectionTitle[index]?._id) ?
																tick :student?.SectionTitleProgress?.find((stp)=> stp?.sectionTitleId == sectionTitle[index-1]?._id) ?unlockGold
																: lock
															}
															style={{
																position: "absolute",
																bottom: "0px",
																left: "60%",
															}}
															className="lockDay"
															alt="lock"
														/>
													</button>
												</div>
											);
										})}
								</Slider>
							</div>

							<div className={tbodystyles.classes}>
								<div className={tbodystyles.grids}>
									<div className={tbodystyles.top}>
										<div className={tbodystyles.flexTop}>
											{st && st.courseTitle
												? st.courseTitle
												: st.projects && st.projects.length
												? "PROJECT"
												: st.courseTitle}
											<div className={tbodystyles.meetingBu}>

												<Meeting moduleName = {localStorage.getItem("ModuleTitle")}/>
											</div>
										</div>
										<div>{st.title}</div>

									</div>
							

									<div className={tbodystyles.bottom}>
										{loadingState && loadingState.length ? (
											<button
												disabled
												className={tbodystyles.bottomContent}>
												{/* <p>{loadingState[0]}</p> */}
												<div
													style={{
														width: "100%",
														// height: "100vh",
														flex: 1,
														display: "grid",
														placeItems: "center",
													}}>
													<LoadingScreen />
												</div>
											</button>
										) : btnTitles.length ? (
											btnTitles.map((e, i) => {
											

												const unlock = savedProgress
													? savedProgress?.find((f) =>
															language === "English"
																? f.videoId == e.videoId
																: f.videoId == e.teluguVideoId
													  )?.progress >= 80
														? tick
														: savedProgress?.find((f) =>
																language === "English"
																	? f.videoId == e.videoId
																	: f.videoId == e.teluguVideoId
														  )?.progress < 80
														? unlockGold
														: lockYellow
													: lockYellow;
													return (
													<Link
														key={i}
														to={
															i === 0 
																?
																"/dashboard" : studentId == "6513e2a7dc706cf1e7a23b0a" && st.Day === "1"? "/dashboard" :studentId === "633d8c8e8f3bcd7e94612929" || studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b"  ? "/dashboard":
																	 st.projects && st.projects.length
																	? "/dashboard"
																	: st.Day === "1"
																	? i === 0
																		? "/dashboard"
																		: `${unlock}` === `${lockYellow}`
																		? null
																		: "/dashboard"
																	: +st.Day ===
																	  (student &&
																			student.SectionTitleProgress &&
																			student.SectionTitleProgress.length + 1)
																	? i === 0
																		? "/dashboard"
																		: `${unlock}` === `${lockYellow}`
																		? null
																		: "/dashboard"
																	: `${unlock}` === `${lockYellow}`
																	? null
																	: "/dashboard"

																
														}
														style={{
															textDecoration: "none",
															color: "black",
														}}>
														<button
															disabled={
																i == 0 ? false : 
																student?.progressId?.find((s)=>s == section[i > 0 ?i-1:i]?._id) ? false :
																	studentId == "6513e2a7dc706cf1e7a23b0a" && st.Day === "1" ? false :
																	studentId === "633d8c8e8f3bcd7e94612929" || studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b" ? false :
																	st&&st.projects && st.projects.length
																		? false
																		: st.Day === "1"
																		? i === 0
																			? false
																			: `${unlock}` === `${lockYellow}`
																			? true
																			: false
																		: +st.Day === student.SectionTitleProgress &&
																		+student.SectionTitleProgress[
																				student.SectionTitleProgress.length - 1
																		].Day +
																				1
																		? (() => {
																				return i === 0
																					? false
																					: `${unlock}` === `${lockYellow}`
																					? true
																					: false;
																		})()
																		: `${unlock}` === `${lockYellow}`
																		? true
																		: false
															}
															className={tbodystyles.bottomContent}
															onClick={() => {
																localStorage.setItem("topic", e.title);
																localStorage.setItem("index", i);
																dispatch(addSection({ value: e, index: i }));
															}}>
															<p>{e.title ? e.title : e}</p>

															{e.title && !st.projects && (
																<span className={tbodystyles.circle}>
																	<img
																		ref={(el) => (tickRef.current[i] = el)}
																		className="checkTick"
																		alt="checktick"
																		src={
																		i === 0?
																			i == 0 && student?.progressId?.find((u)=> u == section[0]?._id)
																			 ? tick :
																				unlock :student?.progressId?.find((u)=> u == section[i]?._id ) ?
																				 tick :
																				 student?.progressId?.find((u)=> u == section[i -1]?._id )? unlockGold
																				: st.Day === "1"
																				? i === 0
																					? `${unlock}` === `${tick}`
																						? tick
																						: unlockGold
																					: `${unlock}` === `${unlockGold}`
																					? unlockGold
																					: `${unlock}` === `${lockYellow}`
																					? lockYellow
																					: tick
																				: +st.Day === student.SectionTitleProgress&&
																				  +student.SectionTitleProgress[
																						student.SectionTitleProgress
																							.length - 1
																				  ].Day +
																						1
																				? i === 0
																					? `${unlock}` === `${tick}`
																						? tick
																						: unlockGold
																					: `${unlock}` === `${unlockGold}`
																					? unlockGold
																					: `${unlock}` === `${lockYellow}`
																					? lockYellow
																					: tick
																				: `${unlock}` === `${unlockGold}`
																				? unlockGold
																				: `${unlock}` === `${lockYellow}`
																				? lockYellow
																				: tick 

																		}
																		width={
																			i === 0 ? "60%" :st.projects && st.projects.length
																				? "60%"
																				: st.Day === "1"
																				? i === 0
																					? `${unlock}` === `${unlockGold}`
																						? "60%"
																						: "70%"
																					: `${unlock}` === `${unlockGold}`
																					? "60%"
																					: `${unlock}` === `${lockYellow}`
																					? "105%"
																					: "70%"
																				: +st.Day ===
																				  (student &&
																						student.SectionTitleProgress &&
																						student.SectionTitleProgress
																							.length + 1)
																				? i === 0
																					? `${unlock}` === `${unlockGold}`
																						? "60%"
																						: "70%"
																					: `${unlock}` === `${unlockGold}`
																					? "60%"
																					: `${unlock}` === `${lockYellow}`
																					? "105%"
																					: "70%"
																				: `${unlock}` === `${unlockGold}`
																				? "60%"
																				: `${unlock}` === `${lockYellow}`
																				? "105%"
																				: "70%"
																		}
																		height={
																			i === 0 ? "60%" :st.projects && st.projects.length
																				? "60%"
																				: st.Day === "1"
																				? i === 0
																					? `${unlock}` === `${unlockGold}`
																						? "60%"
																						: "70%"
																					: `${unlock}` === `${unlockGold}`
																					? "60%"
																					: `${unlock}` === `${lockYellow}`
																					? "105%"
																					: "70%"
																				: +st.Day ===
																				  (student &&
																						student.SectionTitleProgress &&
																						student.SectionTitleProgress
																							.length + 1)
																				? i === 0
																					? `${unlock}` === `${unlockGold}`
																						? "60%"
																						: "70%"
																					: `${unlock}` === `${unlockGold}`
																					? "60%"
																					: `${unlock}` === `${lockYellow}`
																					? "105%"
																					: "70%"
																				: `${unlock}` === `${unlockGold}`
																				? "60%"
																				: `${unlock}` === `${lockYellow}`
																				? "105%"
																				: "70%"
																		}
																	/>
																</span>
															)}
														</button>
													</Link>
												);
											})
										) : (
											<p
												style={{
													textAlign: "center",
													padding: "0.5rem 0",
													fontSize: "1rem",
													fontWeight: "600",
												}}>
												Loading ...
											</p>
										)}
									</div>
								</div>
							</div>
						</div>
						<div className={tbodystyles.right}>
							<div className={tbodystyles.rightTop}>
								<div className={tbodystyles.progressTop}>
									<div>Day - {localStorage.getItem("day")} Progress Report</div>
								</div>
								<div className={tbodystyles.progressBottom}>
									<div>
										<div>
											<span className={tbodystyles.circle}>
												<img
													width="90%"
													src={tick}
													alt="tick"
												/>
											</span>
											<span>{st.projects ? "Projects" : "Lesson"}</span>
										</div>
										<span>
											{st.projects ? st.projects.length : section.length}
										</span>
									</div>
									{!st.projects && (
										<div>
											<div>
												<span className={tbodystyles.circle}>
													<img
														width="90%"
														src={tick}
														alt="tick"
													/>
												</span>
												<span>Quiz</span>
											</div>
											<span>{quizLengthforDay()}</span>
										</div>
									)}
									{!st.projects && (
										<div>
											<div>
												<span className={tbodystyles.circle}>
													<img
														width="90%"
														src={tick}
														alt="tick"
													/>
												</span>
												<span>Assignment</span>
											</div>
											<span>{assignmentsLengthForDay()}</span>
										</div>
									)}
								</div>
							</div>
							<div className={tbodystyles.rightBottom}>
								<div className={tbodystyles.bold}>
									Your certificate is locked!
								</div>
								<button
									style={{
										background: "none",
										outline: "none",
										border: "none",
										objectFit: "contain",
										zIndex:"100"
									}}
									disabled={true}>
									<img
										src={certificate}
										width="100%"
										style={{
											boxShadow: "0px 2px 5px grey",
											borderRadius: "10px",
											filter: "blur(2px)",
											userSelect: "none",
											zIndex: "-10",
											
										}}
										draggable="false"
										onContextMenu="false"
										alt="Certificate_sample"
									/>
								</button>
								<div className={tbodystyles.bold}>
									To unlock your certificate
								</div>
								<div style={{ fontWeight: "300", marginTop: "0.3rem" }}>
									Complete 90% of syllabus
								</div>
								<div>
									Your Progress :
									{Math.floor(
										((completedLessons() + quizProgressFun()) /
											(calculateAllLessons() + allQuizes.length)) *
											100
									)
										? Math.floor(
												((completedLessons() + quizProgressFun()) /
													(calculateAllLessons() + allQuizes.length)) *
													100
										  )
										: ` ${0}`}
									%
								</div>
								<hr />
								<div className={tbodystyles.bottomGrid}>
									<div className={tbodystyles.insideGrid}>
										<div>Lesson</div>
										<div>-</div>
										<div>
											 ({savedProgress?.length && completedLessons()}/
											{sectionTitle?.length && calculateAllLessons()})
										</div>
									</div>
									<div className={tbodystyles.insideGrid}>
										<div>Quiz</div>
										<div>-</div>
										<div>
											({quizProgressFun() ? quizProgressFun() : 0}/
											{allQuizes
												? (() => {
														return allQuizes?.filter(
															(e) => e.courseName === selectedCourse?.course_name
														).length;
												  })()
												: 400}
											)
										</div>
									</div>
									<div className={tbodystyles.insideGrid}>
										<div>Assignment</div>
										<div>-</div>
										<div>
											(0 /
											{allAssignments &&
												allAssignments?.filter((e) => {
													return e.courseName === selectedCourse.course_name;
												}).length}
											)
										</div>
									</div>
									{/* <div className={tbodystyles.insideGrid}>
								<div>Mock Interview</div>
								<div> - </div>
								<div>(0/2)</div>
							</div> */}
								</div>
								<div className={tbodystyles.and}>
									<span>=============== </span> And{" "}
									<span>=============== </span>
								</div>
								<div className={tbodystyles.complete}>Complete this</div>
								<hr />

								<div className={tbodystyles.bottomInGrid}>
									<div>Projects</div>
									<div>-</div>
									<div>
										(0/
										{sectionTitle?.filter((e) => e.projects)
											? (() => {
													let len = sectionTitle?.filter(
														(e) => e.projects
													).length;
													if (len < 10) {
														return `0${len}`;
													} else {
														return len;
													}
											  })()
											: 0}
										)
									</div>
								</div>
								<button
									style={{
										width: "150px",
										height: "30px",
										display: "grid",
										placeItems: "center",
										boxShadow: "0px 0px 6px #f4b34a",
										borderRadius: "1rem",
										cursor: "pointer",
										outline: "none",
										border: "none",
										margin: "auto",
									}}
									onClick={() => {
										navigate("/certificate", { replace: true });
									}}
									disabled={
										Math.floor(
											((completedLessons() + quizProgressFun()) /
												(calculateAllLessons() + allQuizes?.length)) *
												100
										) >= 90
											? false
											: true
									}>
									Download 🔒
								</button>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
}
