import React, { useState, useEffect, useRef } from "react";
import stSideStyle from "../../styles/profileStyles/ProfileSidePanel.module.scss";
import { useDispatch, useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import user from "../../icons/ProfileIcon.png";
import axios from "axios";

import { getStudents, studentprofilepic } from "../../redux/slices/student";

const ProfileSidepanel = ({ tabs, setSelectTab, selectTab }) => {
	const student = useSelector((state) => state.student.value);
	const [files, setFile] = useState({
		name: "",
		url: "",
	});
	const listRef = useRef();
	const dispatch = useDispatch();
	const uploadImage = async (e) => {
		const file = e.target.files[0];
		const formData = new FormData();
		formData.append("file", file);
		formData.append("upload_preset", "temanedtechFileCloud");
		try {
			const res = await axios.post(
				"https://api.cloudinary.com/v1_1/cliqtick/image/upload",
				formData
			);

			dispatch(studentprofilepic({ url: res.data.url })).then(() => {
				dispatch(getStudents());
			});

			setFile({
				name: res.data.original_filename,
				url: res.data.url,
			});

			// window.location.reload();
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {}, []);

	useEffect(() => {
		const setActive = () => {
			const currentRef = listRef.current;
			const lists = currentRef.children;
			for (let list of lists) {
				list.onclick = () => {
					for (let i of lists) {
						i.classList.remove(stSideStyle.active);
					}
					list.classList.add(stSideStyle.active);
					setSelectTab(list.lastChild.children[1].textContent);
				};
			}
		};
		setActive();
	}, [tabs, selectTab]);

	return (
		<div className={stSideStyle.container1}>
			<div className={stSideStyle.profile}>
				<div>
					<img
						src={student.image ? student.image : files.url ? files.url : user}
						alt=""
					/>
					<EditIcon className={stSideStyle.editIcon} />
					<input
						type="file"
						className={stSideStyle.file_upload}
						onChange={(e) => {
							uploadImage(e);
						}}
					/>
				</div>
				<h3>{`${student.firstName ? student.firstName : ""} ${
					student.lastName ? student.lastName : ""
				}`}</h3>
			</div>
			<div className={stSideStyle.tabs}>
				<ul ref={listRef}>
					{tabs.map((e, i) => (
						<button
							disabled={i === tabs.length - 1 ? true : false}
							key={i}>
							{e}
						</button>
					))}
				</ul>
			</div>
		</div>
	);
};

export default ProfileSidepanel;
