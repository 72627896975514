import React, { useEffect, useState } from "react";
import createStyles from "../../styles/createAcc.module.scss";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../icons/techiePandaLogo.png";
import { TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { createStudent } from "../../redux/slices/createAccount";
import { message } from "antd";
export const CreateAccount = () => {
	const [steps, setSteps] = useState("Step1");
	const [messageApi, contextHolder] = message.useMessage();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [values, setValues] = useState({});
	const [password, setPassword] = useState("");
	const nextStep = () => {
		if (steps == "Step1") {
			if (values.password == password) {
				setSteps("Step2");
			} else {
				messageApi.open({
					type: "warning",
					content: "Please enter all your details correctly",
				});
			}
		}
		if (steps == "Step2") {
			dispatch(createStudent(values)).then(()=>{

				navigate("/");
			})
		}
	};
	const prevStep = () => {
		if (steps == "Step2") setSteps("Step1");
	};
	const token = localStorage.getItem("token");

	useEffect(() => {
		if (token == undefined || token == null || (token == "" && !token)) {
			navigate("/createAccount", { replace: true });
		} else {
			navigate(`/MyDashboard`, { replace: true });
		}
	}, [token]);

	const onchange = (e) => {
		setValues({ ...values, [e.target.name]: e.target.value });
	};

	return (
		<>
			{contextHolder}

			<div className={createStyles.container}>
				<div className={createStyles.left}>
					<img
						src="https://res.cloudinary.com/cliqtick/image/upload/v1684308943/create_user_ryynll.jpg"
						width="70%"
					/>
					<Link to="/">
						<button className={createStyles.loginButton}>Login</button>
					</Link>
				</div>
				<div className={createStyles.right}>
					<div className={createStyles.stepsCont}>
						<div
							style={
								steps == "Step1" || steps == "Step2"
									? { backgroundColor: "black" }
									: { backgroundColor: "#f4b34a" }
							}></div>
						<hr />
						<div
							style={
								steps == "Step2"
									? { backgroundColor: "black" }
									: { backgroundColor: "#f4b34a" }
							}></div>
					</div>
					<img
						src={logo}
						alt=""
						className={createStyles.logo}
					/>
					{steps == "Step1" && (
						<div className={createStyles.Step1}>
							<TextField
								id="outlined-search"
								label="First Name"
								type="search"
								name="firstName"
								className={createStyles.inputs}
								onChange={(e) => onchange(e)}
							/>
							<TextField
								id="outlined-search"
								label="Last Name"
								type="search"
								name="lastName"
								className={createStyles.inputs}
								onChange={(e) => onchange(e)}
							/>
							<TextField
								id="outlined-search"
								label="Email"
								type="search"
								name="email"
								className={createStyles.inputs}
								onChange={(e) => onchange(e)}
							/>
							<TextField
								id="outlined-search"
								label="Phone"
								type="number"
								name="phone"
								className={createStyles.inputs}
								onChange={(e) => onchange(e)}
							/>
							<TextField
								id="outlined-search"
								label="Password"
								type="search"
								name="password"
								className={createStyles.inputs}
								onChange={(e) => onchange(e)}
							/>
							<TextField
								id="outlined-search"
								label="Confirm Password"
								type="search"
								className={createStyles.inputs}
								onChange={(e) => setPassword(e.target.value)}
							/>
						</div>
					)}
					{steps == "Step2" && (
						<div
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
							}}>
							<div className={createStyles.Step1}>
								<TextField
									id="outlined-search"
									label="Country"
									type="search"
									name="country"
									className={createStyles.inputs}
									onChange={(e) => onchange(e)}
								/>
								<TextField
									id="outlined-search"
									label="State"
									type="search"
									name="state"
									className={createStyles.inputs}
									onChange={(e) => onchange(e)}
								/>
								<TextField
									id="outlined-search"
									label="City"
									type="search"
									name="city"
									className={createStyles.inputs}
									onChange={(e) => onchange(e)}
								/>
								<TextField
									id="outlined-search"
									label="pin"
									type="search"
									name="zip_code"
									className={createStyles.inputs}
									onChange={(e) => onchange(e)}
								/>
							</div>
							<TextField
								id="outlined-search"
								label="Address"
								type="search"
								name="address"
								className={createStyles.inputsAd}
								multiline
								rows="5"
								onChange={(e) => onchange(e)}
							/>
						</div>
					)}

					<div className={createStyles.flexButtons}>
						<button
							className={createStyles.botton}
							onClick={prevStep}>
							Prev
						</button>
						<button
							className={createStyles.botton}
							onClick={nextStep}>
							{steps == "Step2" ? "Finish" : "Next"}
						</button>
					</div>
				</div>
			</div>
		</>
	);
};
