import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../../graphql";

const sectionProgressSlice = createSlice({
	name: "section Progress",
	initialState: {
		value: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(updateSectionstatus.fulfilled, (state, action) => {
			state.value = action;
		});
	},
});

export const updateSectionstatus = createAsyncThunk(
	"/updateSectionstatus",
	async (args) => {
		const sectionid = args.sectionId;
		const videoProgress = args.videoProgress;
		const quiz = args.quizStatus;
		const assignment = args.assignmentStatus;
		const userId = localStorage.getItem("id");
		const token = localStorage.getItem("token");
		let status;

		if (videoProgress >= 80 && quiz && assignment) {
			status = true;
		} else {
			status = false;
		}
		const obj = {
			sectionId: sectionid,
			status: status,
		};
		const response = await axios.post(
			`${url}/updateStudent`,
			{
				userId,
				type: "SectionProgress",
				obj,
			},
			{
				headers: {
					Authorization: "Bearer " + token,
				},
			},
		);

		const data = await response.data;
		return data;
	},
);

export default sectionProgressSlice.reducer;
