import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import FavoriteIcon from "@mui/icons-material/Favorite";
import ShareIcon from "@mui/icons-material/Share";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import logo from "../../icons/techiePandaLogo2.png";
import { useDispatch, useSelector } from "react-redux";
import Button from "@mui/material/Button";
import cardStyles from "../../styles/profileStyles/choosePlan.module.scss";
import { Payment } from "../../paymement/payment";
import { getAllCourses } from "../../redux/slices/course";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
import courseThumbnail from "../../icons/thumbnail3.jpg";

const ExpandMore = styled((props) => {
	const { expand, ...other } = props;
	return <IconButton {...other} />;
})(({ theme, expand }) => ({
	transform: !expand ? "rotate(0deg)" : "rotate(180deg)",
	marginLeft: "auto",
	transition: theme.transitions.create("transform", {
		duration: theme.transitions.duration.shortest,
	}),
}));

export default function CourseCard() {
	const courses = useSelector((state) => state.course.Courses);
	const student = useSelector((state) => state.student.value);
	const [messageApi, contextHolder] = message.useMessage();
	const shareData = {
		// title: student.,
		text: "Learn web development on Techie Panda!",
		// url: "https://developer.mozilla.org",
	};

	return (
		<>
			{contextHolder}
			<div style={{ display: "flex", flexWrap: "wrap", width: "100%" }}>
				{courses &&
					courses.map((e, i) => {
						
						return (
							<Card
								key={i}
								className={cardStyles.card}
								style={{ marginRight: "2rem" }}
								sx={{
									width: "100%",
									margin: "1rem",
									boxShadow: "0px 1px 5px grey",
									display: "flex",
								}}>
								<CardMedia
									sx={{ width: "30%", objectFit: "center" }}
									component="img"
									height="100%"
									className={cardStyles.thumbnail}
									image={courseThumbnail}
									alt={e.course_name}
								/>
								<div
									style={{
										width: "100%",
									}}>
									<CardHeader
										avatar={
											<Avatar
												sx={{
													bgcolor: "white",
													boxShadow: "0px 0px 3px grey",
												}}
												aria-label="recipe">
												<img
													src={logo}
													alt="logo"
													width={20}
												/>
											</Avatar>
										}
										// action={
										// 	<IconButton aria-label="settings">
										// 		<MoreVertIcon />
										// 	</IconButton>
										// }
										title={e.course_name?.toUpperCase()}
										// subheader={startDate()}
									/>

									<CardContent>
										<Typography
											variant="body2"
											color="text.secondary">
											With the help of a variety of projects, assignments and
											challenges, the program teaches students how to create
											high-quality dynamic websites.
										</Typography>
									</CardContent>
									<CardActions
										disableSpacing
										style={{
											display: "flex",
											justifyContent: "space-between",
										}}>
										<Button
											variant="contained"
											onClick={() => {
												Payment({
													course: e,
													student: student,
													messageApi: messageApi,
												});
											}}>
											{e._id == student?.course?.find((f) => f == e._id)
												? student?.courseEnrolledData?.find((g)=> g.courseId == e._id)?.active == false ? "Course Expired" :"Already Enrolled"
												: "Get Access" }
											{/* {e._id == student &&
										student.course &&
										student.course.find((f) => f == e._id)
											? "You Already Enrolled in this Course"
											: "Get Access"} */}
										</Button>
{/* 
										<ShareIcon
											onClick={async () => {
												try {
													await navigator.share(shareData);
												} catch (err) {
													messageApi.open({
														type: "error",
														content: err.message,
													});
												}
											}}
										/> */}

										{/* <ExpandMore
						expand={expanded}
						onClick={handleExpandClick}
						aria-expanded={expanded}
						aria-label="show more">
						<ExpandMoreIcon />
					</ExpandMore> */}
									</CardActions>
								</div>
							</Card>
						);
					})}
			</div>
		</>
	);
}
