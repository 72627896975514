import React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { addProgress } from "../redux/slices/videoprogressSlice";
import { message } from "antd";
import { getStudents } from "../redux/slices/student";
import { setLanguage } from "../redux/slices/setlanguageSlice";
import { current } from "@reduxjs/toolkit";

const Videos = (props) => {
	const dispatch = useDispatch();
	const selectSec = useSelector((state) => state.selectSelection.value);
	const savedProgress = useSelector((state) => state.videoProgress.value);
	const [messageApi, contextHolder] = message.useMessage();

	/////////////////////////////////////////////////////// Video Start ////////////////////////////////////////////////////////////
	// const [pross ,setProp]=useState("")

	const [videeo, setVideeo] = useState("");
	const SelectedVideo =
		savedProgress && savedProgress.filter((e) => e.videoId === videeo)[0];

	const container = useRef();
	const language = useSelector((state) => state.language);
	// const [isVideoAdded, setIsVideoAdded] = useState(false);
	const [videoRef, setVideoRef] = useState(null);

	const loadVideo = useCallback(
		({ otp, playbackInfo, container, configuration }) => {
			const params = new URLSearchParams("");
			const parametersToAdd = { otp, playbackInfo, ...configuration };
			for (const item in parametersToAdd) {
				params.append(item, parametersToAdd[item]);
			}
			const iframe = document.createElement("iframe");
			iframe.src = iframe.setAttribute("allowfullscreen", "true");
			iframe.setAttribute("allow", "autoplay; encrypted-media");
			iframe.setAttribute("frameborder", "0");
			iframe.style =
				"height:54.5vh; width: 100%;overflow: hidden;  margin:0 auto;";
			iframe.src = "https://player.vdocipher.com/v2/?" + params;

			// setParam(parametersToAdd);

			container.append(iframe);

			// setIsVideoAdded(true);
			return iframe;
		},
		[]
	);

	const handleClick = useCallback(
		(otp, playbackInfo) => {
			// setProp({otp : otp, pbi: playbackInfo})
			if (!container.current) return;
			container.current.innerHTML = "";
			const video = loadVideo({
				otp,
				playbackInfo,
				configuration: { noClipstate: true },
				container: container.current,
			});

			setVideoRef(video);
		},
		[loadVideo]
	);

	const sect = useSelector((state) => state.sections.value);

	const getVidCred = async () => {
		let d;

		if (language?.value === "English") {
			if (props?.creds?.cred?.videoId) {
				d = sect.find((e) => e.videoId === props.creds.cred.videoId).videoId;
				setVideeo(d);
			}
			if (selectSec) {
				d = selectSec.videoId;
				setVideeo(d);
			}
			if (props.defaultsec) {
				d = props.defaultsec.videoId;
				setVideeo(d);
			}
		}
		if (language.value === "Telugu") {
			if (props?.defaultsec) {
				d = props.defaultsec.teluguVideoId
					? props.defaultsec.teluguVideoId
					: (() => {
							messageApi.open({
								type: "error",
								content: "No Telugu Content",
							});
							dispatch(
								setLanguage({
									language: "English",
									videoId: props.defaultsec.videoId,
								})
							);
							return props.defaultsec.videoId;
					  })();
				setVideeo(d);
			}
		}

		try {
			const data = await axios.post(
				"https://server.techiepanda.in/getVideoOTP",
				{
					videoId: d,
				}
			);

			const playbackData = data.data;

			if (playbackData.otp) {
				handleClick(playbackData.otp, playbackData.playbackInfo);
			}
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getVidCred();
	}, [props.creds.cred, videeo, language.value]);
	/////////////////////////////////////////////////////// Video End ////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////// Video Player Start ////////////////////////////////////////////////////////////
	// const [players, setPlayer] = useState(null);
	const [status, setStatus] = useState("NA");
	const [per, setPer] = useState(0);
	const [currentTime, setCurrentTime] = useState({
		presentTime: "",
		totalTime: "",
	});

	useEffect(() => {
		if (videoRef) {
			if (window.VdoPlayer) {
				const player = window.VdoPlayer(videoRef);
				window.player = player;
				// setPlayer(player);

				player.video.addEventListener("play", () => {
					setStatus("Playing");
				});
				player.video.addEventListener("pause", () => setStatus("Paused"));
				player.video.addEventListener("canplay", () => setStatus("Ready"));
				player.video.addEventListener("timeupdate", () => {
					// console.log(metaData)
					// console.log("timeupdate");

					setCurrentTime({
						...currentTime,
						presentTime: player.video?.currentTime,
						totalTime: player.video?.duration,
					});
				});
				if (player.api) {
					player.api.getMetaData().then((e) => {

							player.video.currentTime = SelectedVideo?.progress
								? SelectedVideo?.progress
								: per;

					});
					window.player = player;
				}
			}
		}
	}, [videoRef]);

	/////////////////////////////////////////////////////// Video Player End ////////////////////////////////////////////////////////////

	/////////////////////////////////////////////////////// Video Progress Start ////////////////////////////////////////////////////////////

	const [progressPercent, setProgressPercent] = useState([]);

	useEffect(() => {
		if (videeo) {
			let filter = progressPercent?.filter((e) => e.id === videeo);
			if (filter[0]) {
				let temp = progressPercent?.map((e) => {
					return e;
				});
				const sett = [...new Set(temp)];

				setProgressPercent(sett?.splice(0, -1));

				// console.log("videoId - ", progressPercent);
			} else {
				if (videeo) {
					setProgressPercent([
						{
							per: currentTime,
							id: videeo,
						},
					]);
				}
			}
		}
		
			if (progressPercent&&progressPercent[0]) {
				setPer(
					(progressPercent[0]?.per?.presentTime /
						progressPercent[0]?.per?.totalTime) *
						100 +
						"%"
				);
				if (savedProgress?.length) {
					let currentVideoProgress = savedProgress?.find(
						(e) => e.videoId === progressPercent[0].id
					);

					if (currentVideoProgress) {
						if (
							currentVideoProgress?.progress < 100
						) {

							dispatch(
								addProgress({
									videoId: progressPercent[0].id,
									videoLang: language.value,
									progress:
										(progressPercent[0].per.presentTime /
											progressPercent[0].per.totalTime) *
										100,
								})
							).then(() => {
								dispatch(getStudents());
							});
						}
						else{
							return
						}
					} else {
						dispatch(
							addProgress({
								videoId: progressPercent[0].id,
								videoLang: language.value,
								progress:
									(progressPercent[0].per.presentTime /
										progressPercent[0].per.totalTime) *
									100,
							})
						).then(() => {
							dispatch(getStudents());
						});
					}
				} else {
					dispatch(
						addProgress({
							videoId: progressPercent[0].id,
							videoLang: language.value,
							progress:
								(progressPercent[0].per.presentTime /
									progressPercent[0].per.totalTime) *
								100,
						})
					).then(() => {
						dispatch(getStudents());
					});
				}
			}else{
				console.log("No progress Percent[0]")
		}
	}, [currentTime]);
	// console.log(per);

	/////////////////////////////////////////////////////// Video Progress End ////////////////////////////////////////////////////////////

	return (
		<>
			{contextHolder}
			<div ref={container}></div>
		</>
	);
};

export default Videos;
