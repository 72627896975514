import titlestyles from "../styles/title.module.scss";
import arrow from "../icons/ArrowIcon.png";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { setTitle, setSubtitle } from "../redux/slices/titleAndSubTitle";
import { Button, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { setLanguage } from "../redux/slices/setlanguageSlice";
import { message } from "antd";
import { moduleTitle } from "../redux/slices/SelectedCourse";

export default function Title() {
	const title = useSelector((state) => state.titles.title);
	const subtitle = useSelector((state) => state.titles.subTitle);
	const sections = useSelector((state) => state.sections.value);
	const ModuleTitle = useSelector((state)=>state.selectedCourse.title)
	const MTitle = localStorage.getItem("ModuleTitle")
	const moduleID = localStorage.getItem("moduleId")
	const SectTitle = localStorage.getItem("StTitle")

	const navigate = useNavigate();
	const [messageApi, contextHolder] = message.useMessage();

	const courseTitle = useSelector(
		(state) => state.course?.selectedCourse?.course_name
	) 
	const CourseName = localStorage.getItem("courseName")
	const selectedCourseId = useSelector(
		(state) => state.course?.selectedCourse?._id
	);
	const dispatch = useDispatch();
	const location = useLocation();

	const day = localStorage.getItem("day");


	const items = [
		{
			key: "1",
			label: <div onClick={(event) => setlanguage(event)}>Telugu</div>,
		},
		{
			key: "2",
			label: <div onClick={(event) => setlanguage(event)}>English</div>,
		},
	];

	const setlanguage = (event) => {
		const index = localStorage.index;
		const section = sections[index];
		if (section.videoId || section.teluguVideoId) {
			if (event.target.innerText === "English") {
				dispatch(
					setLanguage({
						language: event.target.innerText,
						videoId: section.videoId,
					})
				);
			}
			if (event.target.innerText === "Telugu") {
				if (section.teluguVideoId) {
					dispatch(
						setLanguage({
							language: event.target.innerText,
							videoId: section.teluguVideoId,
						})
					);
				} else {
					dispatch(
						setLanguage({
							language: "English",
							videoId: section.videoId,
						})
					);
					messageApi.open({
						type: "error",
						content: "No Telugu video for this topic",
					});
				}
			}
		} else {
			messageApi.open({
				type: "error",
				content: "No VideoId for this topic",
			});
		}
	};

	useEffect(() => {
		dispatch(setSubtitle(""));
		dispatch(setTitle(""));
	}, [day, location.pathname, dispatch]);


	return (
		<>
			{contextHolder}
			<div className={titlestyles.titleContainer}>
				<div>
					<div>
						{location.pathname === "/dashboard" ? (
							<button
								onClick={() =>
									navigate(`/Module/${selectedCourseId}`, {
										replace: true,
									})
								}
								className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3}>
								{CourseName?.toUpperCase()}
							</button>
						) : location.pathname === `/Module/${selectedCourseId}` ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate(`/MyDashboard`, {
									replace: true,
								})
							}>

								{CourseName?.toUpperCase()}
							</button>
						) : location.pathname ===
						  `/students/${localStorage.getItem("id")}` ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate(`/students/${localStorage.getItem("id")}`, {
									replace: true,
								})}
							>
								{"Profile Dashboard"?.toUpperCase()}

							</button>
						) : location.pathname === `/MyDashboard` ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate(`/MyDashboard`, {
									replace: true,
								})} >

								{"My Dashboard"?.toUpperCase()}
							</button>
						) : location.pathname === `/courses` ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate(`/courses`, {
									replace: true,
								})} >

								{"Courses"?.toUpperCase()}
							</button>
						) : location.pathname === "/Invoices" ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate("/Invoices", {
									replace: true,
								})}>

								{"Invoices"?.toUpperCase()}
							</button>
						) : location.pathname === `/TimeTable/${moduleID}` ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={() =>
								navigate(`/Module/${selectedCourseId}`, {
									replace: true,
								})}>

								{CourseName?.toUpperCase() }
							</button>
							 ): null}
						&nbsp;
						<img
							width="10px"
							src={arrow}
							alt="arrow"
						/>
						{ location.pathname === `/TimeTable/${moduleID}`||location.pathname === "/dashboard" ? (
							<button className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} >
								<span style={{paddingRight:"0.5rem"}}  className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={()=>navigate(`/Module/${selectedCourseId}`, {
								replace: true,
							})}>

									{MTitle} 	
								</span>
									<img
							width="10px"
							src={arrow}
							alt="arrow"
						/> 
						<span style={{paddingRight:"0.5rem"}}  className={location.pathname === "/dashboard" ?titlestyles.button4:titlestyles.button3} onClick={()=>navigate(`/TimeTable/${moduleID}`, {
								replace: true,
							})}>

							{SectTitle}
						</span>
							</button>
							) : null}
						{location.pathname === "/dashboard" ? ( 
							subtitle && (
							<span>
									<img
							width="10px"
							src={arrow}
							alt="arrow"
						/>
						<span className={location.pathname === "/dashboard" ?titlestyles.button3 : titlestyles.button4}>{title}</span>
								</span>)
						) : null}
					</div>

					{/* for selecting video language */}
					
					{/* <div>
						{location.pathname === "/dashboard" &&
							subtitle === "Video Lecture" && (
								<Dropdown
									arrow="true"
									menu={{
										items,
									}}
									placement="bottom">
									<Button className={titlestyles.button3}>
										Select Language
										<DownOutlined />
									</Button>
								</Dropdown>
							)}
					</div> */}
				</div>
			</div>
		</>
	);
}
