import React, { useEffect } from "react";
import Styles from "../../styles/invoice.module.scss";
import logo from "../../icons/techiePandaLogo.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useDispatch, useSelector } from "react-redux";
import { getPayment } from "../../redux/slices/payment";

export default function Invoice() {
	const sdata = useSelector((state) => state.student.value);
	const paymentData = useSelector((state) => state.payment.value);
	console.log(paymentData);

	const dispatch = useDispatch();
	const exportPdf = () => {
		html2canvas(document.querySelector("#capture")).then((canvas) => {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF();
			pdf.addImage(imgData, "PNG", 0, 0, 210, 150);
			pdf.save("TecheiPandaPaymentInvoice.pdf");
		});
	};
	useEffect(() => {
		if (sdata && sdata.payment) {
			dispatch(getPayment(sdata.payment));
		}
	}, [sdata]);
	return (
		<div className={Styles.invoiceContainer}>
			{paymentData ?
				paymentData.map((e) => {
					return (
						<div
							className={Styles.bill}
							id="capture">
							<div className={Styles.grid}>
								<picture>
									<img
										src={logo}
										alt="logo"
									/>
								</picture>
								<div className={Styles.right}>
									<div className={Styles.original}>ORIGINAL FOR RECIPIENT</div>
									<div className={Styles.tax}>TAX INVOICE</div>

									<div>Invoice #: {e.orderId}</div>
									<div>Invoice date:{e.createdAt}</div>
								</div>
							</div>
							<hr />

							<div className={Styles.grid2}>
								<div className={Styles.Supplier}>
									<div className={Styles.heading}>Supplier details :</div>
									<div>TEMAN EDTECH PVT LTD</div>
									<div>2nd Floor, Sri Sai Villa Chambers, above</div>
									<div>Alpha Hotel, Nad Junction, Shanti Nagar,</div>
									<div>NSTL, Visakhapatnam, Andhra Pradesh</div>
									<div>530027</div>
									<div>GSTN :</div>
									<div>PAN NO :</div>
								</div>
								<div className={Styles.Recipient}>
									<div className={Styles.heading1}>Recipient details:</div>
									<div>{sdata.firstName + sdata.lastName}</div>
									<div>{sdata.email}</div>
									<div>
										<span>{sdata.state} - </span>
										<span>{sdata.city} - </span>
										<span>{sdata.zip_code}</span>
									</div>
								</div>
							</div>

							<div className={Styles.table1}>
								<p className={Styles.desc}>Course Name</p>
								<p>HSN Code</p>
								<p>Quantity</p>
								<p>Taxable Value</p>
								<p>CGST (0%)</p>

								<p>SGST/UGST(0%)</p>
								<p>IGST(18%)</p>
								<p>Total Amount</p>
							</div>
							<div className={Styles.table}>
								<p className={Styles.desc}>{e?.couseName}</p>
								<p>INR</p>
								<p>1.0</p>
								<p>402.54</p>
								<p>{(parseInt(e?.amountCharged?.split("INR")) * 18) / 100}</p>

								<p>SGST/UGST(0%)</p>
								<p>SGST/UGST(0%)</p>
								<p>{e?.amountCharged?.split("INR")} INR</p>
							</div>

							<div className={Styles.table1}>
								<p className={Styles.desc}>Total</p>
								<p></p>
								<p></p>
								<p>402.54</p>
								<p>{(parseInt(e?.amountCharged?.split("INR")) * 18) / 100}</p>
								<p></p>
								<p></p>

								<p>{e?.amountCharged?.split("INR")} INR</p>
							</div>
							<div className={Styles.pay}>
								PAYMENT INFO:
								<p>
									*This is a system generated invoice and does not require a
									signature or a digital signature
								</p>
							</div>
							<div
								className={Styles.download}
								onClick={exportPdf}>
								Download Invoice
							</div>
						</div>
					);
				}) : "No Invoices"}
		</div>
	);
}
