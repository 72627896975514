import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const SectionSlice = createSlice({
	name: "Section",
	initialState: {
		value: [],
		state: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getSection.fulfilled, (state, action) => {
			state.value = action.payload;
			state.state = [];
		});
		builder.addCase(getSection.pending, (state, action) => {
			state.state = ["LOADING..."];
		});
	},
});

export const getSection = createAsyncThunk("/getSection", async (args) => {
	try {
		const token = localStorage.getItem("token");
		const fetchSectionArr = args.map((e) => {
			return axios.post(
				`${url}/getSection`,
				{ sectionId: e },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});
		const data = await Promise.allSettled(fetchSectionArr);

		return data.map((e) => e.value.data);
	} catch (error) {
		return new Error(error.message);
	}
});

export default SectionSlice;
