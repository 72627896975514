import React, { useEffect, useState } from "react";
import LoadingScreen from '../components/LoadingScreen';
import meetingStyles from "./meeting.module.scss"
import axios from "axios"

const Meeting = ({moduleName}) => {

    const [meetingDetails, setMeetingDetails] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const fetchMeetingdetails = async () => {
      const { data } = await axios.get(
        "https://livebackend.techiepanda.in/meetings/meetingdetails",
        {
          headers: {
            "ngrok-skip-browser-warning": "69420",
          },
        }
      );
      setMeetingDetails(data);
      return data;
    };
    useEffect(() => {
      fetchMeetingdetails();
    }, []);

    const [token , setToken ]= useState("")

useEffect(()=>{
  axios.get("https://livebackend.techiepanda.in/getToken").then((res)=>setToken(res.data)).catch((err)=>console.log(err))
},[token])
  
    const msg = "meeting not started";
   
    const joinMeeting = async (meetingData) => {
      try {
        setIsLoading(true);
        const { data } = await axios.post(
          `https://webexapis.com/v1/meetings/join`,
          {
            meetingId: meetingData.id,
            joinDirectly: false,
            password: meetingData.password,
          },
          {
            headers: {
              Authorization: "Bearer " + token,
            },
          }
        );
        if (data) {
          setIsLoading(false);
          // console.log(data);
          // window.location.href = data.joinLink;
        }
      } catch (error) {
        return alert(msg);
      }
    };
    const getMeeting = async (arg, meetingId, password) => {
      try {
        setIsLoading(true);
        const { data } = await axios.get(
          `https://webexapis.com/v1/meetings/${meetingId}`,
          {
            headers: {
              Authorization: "Bearer " + token,
              password: password,
            },
          }
        );
        if (data.status === "pending") setIsLoading(true);
        if (data.title === arg) {
          joinMeeting(data);
        } else {
          alert(msg);
        }
      } catch (error) {
        return alert(msg);
      }
    };
    const handleMeeting = (arg) => {
      const currentMeeting =
        meetingDetails && meetingDetails?.find((e) => e?.title === arg);
      if (!currentMeeting) alert(msg);
      if (arg === currentMeeting?.title) {
        getMeeting(arg, currentMeeting?.id, currentMeeting?.password);
      }
    };

  
    return (
      <>
        {isLoading ? (
          <LoadingScreen />
        ) : (
          <div >
          
            <div className={meetingStyles.gridContainer}>
              {/* {modules.map((e, i) => {
                return ( */}
                  <div className={meetingStyles.card} onClick={() => handleMeeting(moduleName)}>
                        Join meeting
                  </div>
                {/* );
              })} */}
            </div>
            
          </div>
        )}
      </>
    );
}

export default Meeting
