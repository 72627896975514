import { createSlice } from "@reduxjs/toolkit";

const SelectedCourse = createSlice({
	name: "Selected",
	initialState: {
		value: "",
        id:"",
		title:""
	},
	reducers: {
		addCourse: (state, action) => {

			state.id = action.payload;

		},
		moduleTitle : (state,action)=>{
			state.title = action.payload
		}
	},
});

export const { addCourse,moduleTitle } = SelectedCourse.actions;

export default SelectedCourse.reducer;