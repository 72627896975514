import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../../graphql";

const createStudentSlice = createSlice({
	name: "CreateStudent",
	initialState: {
		value: [],
		status: "",
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(createStudent.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const createStudent = createAsyncThunk(
	"/createStudent",
	async (args) => {
		try {
			const response = await axios.post(`${url}/addStudent`, args);

			return response.body;
		} catch (error) {
			console.log(error);
		}
	}
);

export default createStudentSlice;
