import React from "react";
import stBody from "../../styles/profileStyles/ProfileBody.module.scss";
import { useForm } from "react-hook-form";
// import VisibilityIcon from "@mui/icons-material/Visibility";
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import EnterancePage from "./EnterancePage";
// import ShowPlans from "./ShowPlans";
// import Notifications from "./Notifications";
import Resume from "./Resume";
import { useDispatch } from "react-redux";
import { studentprofileUpdate } from "../../redux/slices/student";
import { Password } from "./password";
import Invoice from "./invoice";
import { message } from "antd";
// import axios from "axios";

const ProfileBody = ({ student, selectTab }) => {
	const [messageApi, contextHolder] = message.useMessage();
	// const [details, setDetails] = useState({
	// 	firstName: "",
	// 	lastName: "",
	// 	email: "",
	// 	phone: "",
	// 	address: "",
	// 	city: "",
	// 	state: "",
	// 	zipcode: "",
	// 	country: "",
	// 	password: "",
	// });
	const dispatch = useDispatch();
	// const [show, setShow] = useState(false);
	const {
		firstName,
		lastName,
		email,
		phone,
		address,
		dob,
		city,
		country,
		state,
		zip_code,
		gender,
	} = student;
	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm();

	const onSubmit = async (data) => {
		// const axios = await axios("https:rest.temanedtech.com/")
		dispatch(studentprofileUpdate(data)).then(() => {
			messageApi
				.open({
					type: "success",
					content: "Uploaded Successfully",
				})
				.catch((err) => {
					messageApi.open({
						type: "error",
						content: err.message,
					});
				});
		});
	};
	return (
		<div className={stBody.container2}>
			{selectTab === "" ? (
				<EnterancePage />
			) : selectTab === "Edit Profile" ? (
				<>
					<section className={stBody.title}>
						<h2>{selectTab}</h2>
					</section>
					<section className={stBody.main}>
						<main>
							<form onSubmit={handleSubmit(onSubmit)}>
								<div>
									<span>
										<label>First Name</label>
										<input
											defaultValue={firstName ? firstName : null}
											placeholder="Enter Your First Name here"
											{...register("firstName", { required: true })}
										/>
										{errors.firstName && <span>Firstname is required</span>}
									</span>
									<span>
										<label>Last Name</label>
										<input
											defaultValue={lastName ? lastName : null}
											placeholder="Enter Your Last Name here"
											{...register("lastName", { required: true })}
										/>
										{errors.lastName && <span>Lastname is required</span>}
									</span>
								</div>
								<div>
									<span>
										<label>Email</label>
										<input
											defaultValue={email}
											placeholder="Enter Your Email here"
											disabled
										/>
										{errors.email && <span>Email is required</span>}
									</span>
								</div>
								<div>
									<span>
										<label>Contant Number</label>
										<input
											defaultValue={phone ? phone : null}
											type="number"
											placeholder="Enter Your Phone Number here"
											{...register("phone", {
												maxLength: 10,
											})}
										/>
										{errors.phone && (
											<span>Check entered number and try again</span>
										)}
									</span>
								</div>
								<div>
									<span>
										<label>Address</label>
										<textarea
											defaultValue={address ? address : null}
											type="text"
											placeholder="Enter Your Address here"
											{...register("address")}
										/>
										{/* {errors.phone && (
									<span>Check  and try again</span>
								)} */}
									</span>
								</div>
								<div>
									<span>
										<label>City</label>
										<input
											defaultValue={city ? city : null}
											placeholder="Enter Your city here"
											{...register("city", { required: true })}
										/>
										{errors.firstName && <span>City field is required</span>}
									</span>
									<span>
										<label>State</label>
										<input
											defaultValue={state ? state : null}
											placeholder="Enter Your state here"
											{...register("state", { required: true })}
										/>
										{errors.lastName && <span>State field is required</span>}
									</span>
								</div>
								<div>
									<span>
										<label>Zip Code</label>
										<input
											defaultValue={zip_code ? zip_code : null}
											placeholder="Enter Your zip code here"
											{...register("zip_code", { required: true })}
										/>
										{errors.city && <span>City field is required</span>}
									</span>
									<span>
										<label>Country</label>
										<input
											defaultValue={country ? country : null}
											placeholder="Enter Your country here"
											{...register("country", { required: true })}
										/>
										{errors.state && <span>State field is required</span>}
									</span>
								</div>
								<div>
									<span>
										<label>Date Of Birth</label>
										<input
											style={{
												paddingRight: "1rem",
											}}
											defaultValue={dob ? dob : null}
											type="date"
											placeholder="Enter Your zip code here"
											{...register("dob", { required: true })}
										/>
										{errors.city && (
											<span>Date Of Birth field is required</span>
										)}
									</span>
									<span>
										<label>Select Your Gender</label>
										<select
											className={stBody.gender}
											{...register("gender")}>
											<option>{gender ? gender : "Select Gender"}</option>
											<option value="female">Female</option>
											<option value="male">Male</option>
											<option value="other">Other</option>
										</select>
										{/* {errors.state && <span>State field is required</span>} */}
									</span>
								</div>
								<div>
									{/* <span className={stBody.pass}>
										<label>Password</label>
										<input
											type={show ? "text" : "password"}
											placeholder="Enter Your Phone Number here"
											{...register("password", {
												required: true,
											})}
										/>
										{show ? (
											<VisibilityIcon
												className={stBody.showIcon}
												onClick={() => setShow(false)}
											/>
										) : (
											<VisibilityOffIcon
												className={stBody.showIcon}
												onClick={() => setShow(true)}
											/>
										)}
										{errors.password && <span>Enter Your password here</span>}
									</span> */}
								</div>

								<input
									id={stBody.submit}
									type="submit"
								/>
							</form>
						</main>
					</section>
				</>
			) : selectTab === "Change Password" ? (
				<Password />
			) : selectTab === "Invoices" ? (
				<Invoice />
			) : selectTab === "Make Your Resume" ? (
				<Resume />
			) : null}
		</div>
	);
};

export default ProfileBody;
