import React from "react";
import AssignStyles from "../styles/Assignments.module.scss";
import { useSelector } from "react-redux";

const Assignments = () => {
	const assignmentsData = useSelector((state) => state.assignment.value[0]);
	const title = useSelector((state) => state.titles.title);


	return (
		<div className={AssignStyles.main}>
			<h3>
				{assignmentsData &&
					assignmentsData.VideoTitle == title &&
					"Assignments :"}
			</h3>
			{/* <div className={AssignStyles.container1}>
				
				<h4>Problem Description</h4>
				<p>Create a program to print a sentence</p>
				<ul>
					<li>
						{" "}
						Print <code>"Javascript is interesting"</code> on the Screen
					</li>
				</ul>
			</div> */}

			<div className={AssignStyles.container1}>
				{assignmentsData && assignmentsData.VideoTitle == title ? (
					<p
						dangerouslySetInnerHTML={{
							__html: JSON.parse(assignmentsData.content),
						}}></p>
				) : (
					"No Assignments"
				)}
			</div>

			{/* <div className={AssignStyles.container2}>
				<h4>Example</h4>
				<div className={AssignStyles.output}>
					<p>Expected Output</p>
					<p>Javascript is interesting</p>
				</div>
			</div> */}
		</div>
	);
};

export default Assignments;
