import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { url } from "./../../graphql/index";

const StudentSlice = createSlice({
	name: "Students",
	initialState: {
		value: {},
		profilepicStatus: "",
		profileUpdate: "",
		otpStatus: {},
		error: "",
		changeStatus :""
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getStudents.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(studentprofilepic.fulfilled, (state, action) => {
			state.profilepicStatus = "Profile Pic Updated";
		});
		builder.addCase(studentprofileUpdate.fulfilled, (state, action) => {
			state.profileUpdate = "Profile Updated";
		});
		builder.addCase(VerifyOtp.fulfilled, (state, action) => {
			state.otpStatus = action.payload;
		});
		builder.addCase(PasswordLogin.fulfilled, (state, action) => {
			state.error = action.payload;
		});
		builder.addCase(changeCourseStatus.fulfilled, (state, action) => {
			state.changeStatus = action.payload;
		});
	},
});
const id = localStorage.getItem("id");

export const updateStudent = createAsyncThunk(
	"/updateStudent",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			const res = await Axios.post(
				`${url}/updateStudent`,
				{
					userId: id,
					progress: {
						videoId: args.videoId,
						per: args.percentage,
					},
					browser: null,
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			const data = res.data;
			return data;
		} catch (error) {
			console.log(error.message);
		}
	}
);

export const updateStudentProgressForDay = createAsyncThunk(
	"/updateStudentProgressForDay",
	async (args) => {
		console.log(args);
		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");
		try {
			const res = await Axios.post(
				`${url}/updateStudent`,
				{
					userId: id,
					SectionTitleProgress: {
						courseId: args.courseId,
						sectionTitleId: args.sectionTitleId,
						language: args.language,
						Day: args.Day,
						completed: args.status,
						CompletedTime : new Date()
					},
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			const data = res.data;
			return data;
		} catch (error) {
			console.log(`error in updateStudentProgressForDay is ${error.message}`);
		}
	}
);
export const updateStudentProgressForDayTelugu = createAsyncThunk(
	"/updateStudentProgressForDayTelugu",
	async (args) => {
		const token = localStorage.getItem("token");
		const id = localStorage.getItem("id");
		try {
			const res = await Axios.post(
				`${url}/updateStudent`,
				{
					userId: id,
					SectionTitleProgressTelugu: {
						courseId: args.courseId,
						sectionTitleId: args.sectionTitleId,
						language: args.language,
						Day: args.Day,
						completed: args.status,
					},
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			const data = res.data;
			return data;
		} catch (error) {
			console.log(`error in updateStudentProgressForDay is ${error.message}`);
		}
	}
);

export const studentprofileUpdate = createAsyncThunk(
	"/updateProfileDetails",
	async (req) => {
		try {
			const token = localStorage.getItem("token");
			const response = await Axios.post(
				`${url}/updateStudent`,
				{
					userId: id,
					...req,
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const studentprofilepic = createAsyncThunk(
	"/updateProfilepic",
	async (req) => {
		try {
			const token = localStorage.getItem("token");
			const response = await Axios.post(
				`${url}/updateProfile`,
				{
					userId: id,
					url: req.url,
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			console.log(response);
			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const getStudents = createAsyncThunk("/getStudents", async () => {
	const token = localStorage.getItem("token");

	const response = await Axios.post(
		`${url}/getStudent`,
		{
			studentId: id,
		},
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	const data = response.data;

	return data;
});

export const changeCourseStatus = createAsyncThunk("/changeCourseStatus", async (args) => {
	const token = localStorage.getItem("token");
	const response = await Axios.post(
		`${url}/updateStudent`,
		{
			userId: id,...args
		},
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	const data = response.data;

	return data;
});

export const VerifyOtp = createAsyncThunk("/verifyOtp", async (args) => {
	try {
		const response = await Axios.post(`${url}/verifyOtp`, args);

		return response;
	} catch (error) {
		console.log(error);
	}
});
export const PasswordLogin = createAsyncThunk(
	"/PasswordLogin",
	async (args) => {
		try {
			const response = await Axios.post(`${url}/studentLogin`, {
				...args,
			});
			console.log(response);

			if (response.data.errors) return response.data.errors[0];

			let token = response.data.token;
			let userId = response.data.userID;

			localStorage.setItem("token", token);
			localStorage.setItem("id", userId);

			//   Axios.defaults.headers.common["Authorization"] =  token;
			const data = response.data;
			return data;
		} catch (error) {
			console.log(error);
		}
	}
);

// export const studentProgress = createAsyncThunk("/studentProgress" , async(args)=>{
// 	const res = await Axios.post(
// 		`${url}/studentProgress/getStudentProgress`,
// 		{
// 			type: type,
// 			userId: id,
// 			progress: {
// 				id: args.quizId,
// 				status: args.status,
// 			},
// 		},
// 		{
// 			headers: {
// 				Authorization: "Bearer " + token,
// 			},
// 		}
// 	);
// })

export default StudentSlice;
