import Header from "../components/header";
import Title from "../components/title";

export default function Head({ setCoursesClicked }) {
	return (
		<div>
			<Header setCoursesClicked={setCoursesClicked} />
			<Title />
		</div>
	);
}
