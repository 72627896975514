import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../../graphql";

const assignmentSlice = createSlice({
	name: "Assignments",
	initialState: {
		value: [],
		allAssignments: [],
	},
	extraReducers: (builder) => {
		builder.addCase(fetchAssignments.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(fetchAssignments.rejected, (state, action) => {

			state.value = "No Assignments";
		});
		builder.addCase(fetchAllAssignments.fulfilled, (state, action) => {
			state.allAssignments = action.payload[0];
		});
		// builder.addCase(fetchAllAssignments.pending, (state, action) => {
		// 	state.allAssignments = "LOADING...";
		// });
	},
	reducer: {
		addAssignmnetsError: (state, action) => {
			state.value = "No Assignments";
		},
	},
});

export const fetchAssignments = createAsyncThunk(
	"/fetchAssignments",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			const AssignmentId = args.AssignmentId;
			const res = await axios.post(
				`${url}/getOneAssignment`,
				{
					AssignmentId,
				},
				{
					headers: {
						"Content-Type": "application/json; charset=utf-8",
						Authorization: "Bearer " + token,
					},
				}
			);

			const data = await Promise.allSettled([res]);
			return data.map((e) => e.value.data);
		} catch (error) {
			console.log(error.message);
			return error.message;
		}
	}
);
export const fetchAllAssignments = createAsyncThunk(
	"/fetchAllAssignments",
	async () => {
		try {
			const token = localStorage.getItem("token");
			const res = await axios.get(`${url}/getAllAssignment`, {
				headers: {
					"Content-Type": "application/json; charset=utf-8",
					Authorization: "Bearer " + token,
				},
			});
			const data = await Promise.allSettled([res]);
			return data.map((e) => e.value.data);
		} catch (error) {
			console.log(error.message);
			return error.message;
		}
	}
);

export const { addAssignmnetsError } = assignmentSlice.actions;

export default assignmentSlice.reducer;
