import { ArrowLeftOutlined } from "@ant-design/icons";
import React, { useRef, useEffect, useState } from "react";
import steps from "../styles/Steps.module.scss";
import bulb from "../media/Bulb (1).png";
import Videos from "./Videos";
import Quiz from "./Quiz";
import Assignments from "./Assignments";
import { useSelector, useDispatch } from "react-redux";
import { setSubtitle, setOpenModel, setTitle } from "../redux/slices/titleAndSubTitle";
import arrow from "../icons/ArrowIcon.png";
import { message } from "antd";
import { getQuiz, emptyQuiz } from "../redux/slices/quiz";
import { getStudents, updateStudentProgressForDay } from "../redux/slices/student";
// import { addAssignmnetsError } from "../redux/slices/assignmentSlice";
import { fetchAssignments } from "../redux/slices/assignmentSlice";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";

const Steps = ({
	cred,
	setVideoCreds,
	show,
	selectSec,
	defaultsec,
	disRef,
	st,
}) => {

	const eleRef = useRef();
	const testRef = useRef();
	const [dat, setDat] = useState("");
	const [notebook, setNoteBook] = useState("");
	const content = useSelector((state) => state.content.value);
	const sect = useSelector((state) => state.sections.value);
	const title = useSelector((state) => state.titles.title);
	const subtitle = useSelector((state) => state.titles.subTitle);
	const openModel = useSelector((state) => state.titles.model);
	const dispatch = useDispatch();
	const [messageApi, contextHolder] = message.useMessage();
	const currentTopic = localStorage.getItem("topic");
	const savedProgress = useSelector((state) => state.videoProgress.value);
	const projects = useSelector((state) => state.project.value);
	const [project, setprojects] = useState([]);
	const loading = useSelector((state) => state.content.status);
	const language = useSelector((state) => state.language.value);
	const courseId = localStorage.getItem("courseId")

	const fileRef = useRef();




	const sid = localStorage.getItem("sId")

	useEffect(() => {
		if (projects&&projects.length) {
			let projectFilter = projects.find((e) => e.title == title);

			let projectContent = projectFilter && JSON.parse(projectFilter.content);
			setprojects(projectContent);
		}
	}, [project,projects, title]);

	const error = () => {
		messageApi.open({
			type: "error",
			content: "Please select a section and then click the tabs",
		});
	};

	useEffect(()=>{

		setVideoCreds(selectSec)
	},[])

	const showSections = () => {
		if (eleRef && eleRef.current) {
			const eleRefCurrent = eleRef.current;
			const e = eleRefCurrent.children;
			for (let i = 0; i <= e.length - 1; i++) {
				e[i].onclick = () => {
					if (title) {
						for (let tab of e) {
							tab.style.backgroundColor = "#f4b34a98";
						}
						e[i].style.backgroundColor = "black";

						dispatch(setSubtitle(e[i].getAttribute("data")));
						dispatch(setOpenModel(false));
					} else {
						dispatch(setOpenModel(true));
					}
				};
			}
		}
	};

	const delNotefromText = () => {

if(content && content.content){

	const newContentData =content && content?.content ? JSON.parse(content?.content)?.split("Note:")[1]?  JSON.parse(content?.content)?.split("Note:") : JSON.parse(content?.content)?.split("Note :") :null

	setDat(newContentData[0])
	setNoteBook(newContentData[1])
}else{

	const testcurrent = testRef.current;
	const e = dat && testcurrent ? testcurrent.children : null;
	if (testcurrent && testcurrent.children && !st?.projects) {
		for (let i in testcurrent.children) {
			if (i == testcurrent.children.length - 1) {
				testcurrent.children[i].style.display = "none";
			}
		}
		
		dat &&
		testcurrent &&
		setNoteBook(e[e.length - 1] ? e[e.length - 1].innerText : null);
	}
}

	};

	const backToPrevious = () => {
		if (!st.projects || st?.Guided == true) {
			const sectionsArr = [];
			sect.forEach((e, i) => {
				sectionsArr.push(e.title);
				if (currentTopic && currentTopic === e.title) {
					if (sectionsArr.indexOf(e.title) === 0) {
						return;
					} else {
						localStorage.setItem("index", i - 1);
						localStorage.setItem("topic", sect[i - 1].title);
						setVideoCreds(sect[i - 1]);
					}
				}
			});
		} else {
			const sectionsArr = [];
			projects.forEach((e, i) => {
				sectionsArr.push(e.title);

				const filteredProj=  projects.filter((f)=>f.title == title)

				if (title && title === e.title) {

					const projectsLength = projects.length;
					if (sectionsArr.indexOf(e.title) >= projects.indexOf(filteredProj[0]) < 1) {
						messageApi.open({
							type: "warning",
							content: "Reached 1st Section Of Project Sections",
						});
						return;
					} else {
						dispatch(getStudents());
						localStorage.setItem("index", projects.indexOf(filteredProj[0]) - 1);
						setVideoCreds(projects[projects.indexOf(filteredProj[0]) - 1]);
						dispatch(setTitle(projects[projects.indexOf(filteredProj[0]) - 1] ? projects[projects.indexOf(filteredProj[0]) - 1].title : projects[0].title))
					}
				}
			});
		}
	};


	const getAssignments = () => {
		if (cred ||selectSec) {

			if (cred.Assignment ) {
				dispatch(fetchAssignments({ AssignmentId: cred.Assignment }))
			} 
			else if (selectSec.Assignment ) {
				dispatch(fetchAssignments({ AssignmentId: selectSec.Assignment }))
			} 
			else {

				console.log("no assignments");
			}
		} else {
			console.log("no creds");
		}
	};

	useEffect(()=>{
		getAssignments()
	},[subtitle,cred?._id])
	const getQuizs = () => {
		if (sect) {

				const filteredSec = sect?.find((e)=>e.title === title)
				if (filteredSec?.quiz) {
					dispatch(getQuiz(filteredSec.quiz));
				} else {
					dispatch(emptyQuiz());

				}
		} else {
			if (defaultsec.quiz) {
				if (title === defaultsec.title) {
					dispatch(getQuiz(defaultsec.quiz));
				} else {
					dispatch(emptyQuiz());

				}
			}
		}
		dispatch(getStudents());
	};

	const nextTopic = () => {
		if (st?.Guided == true) {

			const getVideoPer = savedProgress?.find((l)=>l.videoId == cred.videoId )

					if (getVideoPer.progress >= 80) {
					
						const sectionsArr = [];
						const sectLen = sect?.length;
						sect.forEach((e, i) => {
							sectionsArr.push(e.title);
							if (currentTopic && currentTopic === e.title) {
								if (sectionsArr.indexOf(e.title) >= sectLen - 1) {
									messageApi.open({
										type: "warning",
										content: "End Of Current Day Sections",
									});
									return;
								} else {

									dispatch(getStudents());
									localStorage.setItem("index", i + 1);
									localStorage.setItem("topic", sect[i + 1].title);
									setVideoCreds(sect[i + 1]);
								}
							}
						});
						dispatch(setSubtitle("Video Lecture"));
					} else {
						messageApi.open({
							type: "warning",
							content: "Complete The Video",
						});
					}
				
	
			} 	
		else if (!st?.projects ) {

					const getVideoPer = savedProgress?.find((l)=>l.videoId == cred.videoId )

							if (getVideoPer.progress >= 80) {
							
								const sectionsArr = [];
								const sectLen = sect?.length;
								sect.forEach((e, i) => {
									sectionsArr.push(e.title);
									if (currentTopic && currentTopic === e.title) {
										if (sectionsArr.indexOf(e.title) >= sectLen - 1) {
											messageApi.open({
												type: "warning",
												content: "End Of Current Day Sections",
											});
											return;
										} else {

											dispatch(getStudents());
											localStorage.setItem("index", i + 1);
											localStorage.setItem("topic", sect[i + 1].title);
											setVideoCreds(sect[i + 1]);
										}
									}
								});
								dispatch(setSubtitle("Notes"));
							} else {
								messageApi.open({
									type: "warning",
									content: "Complete The Video",
								});
							}
						
			
		} 
	
		else {
			projects.forEach((e, i) => {
				sectionsArr.push(e.title);

				const filteredProj=  projects.filter((f)=>f.title == title)

				if (title && title === e.title) {

					const projectsLength = projects.length;
					if (sectionsArr.indexOf(e.title) >= projectsLength - 1) {
						messageApi.open({
							type: "warning",
							content: "End Of Project Sections",
						});
						return;
					} else {
						dispatch(getStudents());
						localStorage.setItem("index", projects.indexOf(filteredProj[0]) + 1);
						setVideoCreds(projects[projects.indexOf(filteredProj[0]) + 1]);
						dispatch(setTitle(projects[projects.indexOf(filteredProj[0]) + 1].title))
					}
				}
			});
			dispatch(setSubtitle("Notes"));
		}
	};
			const sectionsArr = [];


			useEffect(()=>{

				getQuizs()
			},[subtitle])

	useEffect(() => {
		const dataas = sect.find((e) => e.title == content.VideoTitle)
		? JSON.parse(content.content)
		: null;

		setDat(dataas);
	}, [content]);

	useEffect(() => {
		showSections();
		delNotefromText();
	}, [sect, dat, cred, defaultsec,content.content]);
	
	const findCurrSec = sect?.find((e)=>e._id == sid)
	useEffect(()=>{
		if(sect?.findIndex(e => e._id == sid )+1 == sect?.length && subtitle == "Your Assignment" && savedProgress?.find(e => e.videoId == findCurrSec?.videoId)?.progress >= 80)DispatchFunction()

	},[sect,subtitle,findCurrSec?.videoId])


	const DispatchFunction = ()=>{

		dispatch(
			updateStudentProgressForDay({
				courseId: courseId,
				sectionTitleId: st._id,
				Day: st.Day,
				language: language,
				status: true,
			})
		).then(() => dispatch(getStudents()))

	}

	return (
		<div className={show ? steps.main : `${steps.main} ${steps.nomain}`}>
			{contextHolder}
			<main>
				<div className={steps.title}>
					<h3 onClick={(event) => backToPrevious(event)}>
						<ArrowLeftOutlined />
						<a> Back To Previous Lesson</a>
					</h3>
				</div>

				{!st?.projects &&  !st?.Guided &&(
					<div
						className={steps.dashes}
						ref={eleRef}>
						<div
							style={subtitle === "Notes" ? { backgroundColor: "black" } : null}
							onClick={openModel ? error : null}
							className={steps.notes}
							data="Notes"></div>

						<div
							onClick={openModel ? error : null}
							style={
								subtitle === "Video Lecture"
									? { backgroundColor: "black" }
									: null
							}
							className={steps.videos}
							data="Video Lecture"></div>
					
						<div
							onClick={openModel ? error : getQuizs}
							className={steps.quiz}
							style={
								subtitle === "Take A Quiz" ? { backgroundColor: "black" } : null
							}
							data="Take A Quiz"></div>

						<div
							onClick={openModel ? error : getAssignments}
							className={steps.assignments}
							style={
								subtitle === "Your Assignment"
									? { backgroundColor: "black" }
									: null
							}
							data="Your Assignment"></div>
					</div>
				)}

				<div className={steps.body}>
					{st?.Guided == true ?(
							<section
								id={steps.videos}
								style={
									show
										? null
										: {
												width: "100vw",
										}
								}>
								<Videos
									selectSec={selectSec}
									defaultsec={defaultsec}
									creds={{ cred }}
									show={show}
								/>
							</section>
						) : <div>
						{ subtitle === "Notes"  && (
							<>
								{loading ? (
									<>
										<div
											style={{
												width: "100%",
												height: "100%",
												display: "flex",
												justifyContent: "center",
												alignItems: "center",
											}}>
											<LoadingScreen />
										</div>
									</>
								) : (
									<section id={steps.notes}>
										{st && st.projects && project ? (
											<div
												className="test"
												ref={testRef}
												dangerouslySetInnerHTML={{
													__html: project ? (
														project
													) : (
														<div
															style={{
																width: "100%",
																height: "100%",
																display: "flex",
																justifyContent: "center",
																alignItems: "center",
															}}>
															<LoadingScreen />
														</div>
													),
												}}
												style={
													show
														? {
																padding: "0px 1rem",
																display: "flex",
																flexDirection: "column",
																alignItems: "center",
														  }
														: {
																width: "80%",
																display: "flex",
																flexDirection: "column",
																alignItems: "center",
																margin: " 0 auto",
														  }
												}></div>
										) : (
											<div
												className="test"
												ref={testRef}
												dangerouslySetInnerHTML={{ __html: dat }}
												style={
													show
														? {
																padding: "0px 1rem",
																display: "flex",
																flexDirection: "column",
																alignItems: "center",
														  }
														: {
																width: "80%",
																display: "flex",
																flexDirection: "column",
																alignItems: "center",
																margin: " 0 auto",
														  }
												}></div>
										)}
	
										{st?.projects
											? null
											: dat && notebook && (
													<div
														className={steps.note}
														style={
															show
																? null
																: {
																		width: "48%",
																		margin: "0 auto",
																		marginBottom:"1rem"
																  }
														}
														
														>
														<img
															src={bulb}
															alt="bulb"
														/>
														<div dangerouslySetInnerHTML={{ __html: `Note : ${notebook }` }}>
	
														</div>
														
													</div>
											  )}
									</section>
								)}
							</>
						)}
	
						{subtitle === "Video Lecture" && (
							<section
								id={steps.videos}
								style={
									show
										? null
										: {
												width: "100vw",
										  }
								}>
								<Videos
									selectSec={selectSec}
									defaultsec={defaultsec}
									creds={{ cred }}
									show={show}
								/>
							</section>
						)}
						{subtitle === "Take A Quiz" && (
							<section
	
								id={steps.quiz}
								style={
									show
										? null
										: {
												width: "100vw",
										  }
								}>
								<Quiz show={show}/>
							</section>
						)}
						{subtitle === "Your Assignment" && (
							<section
								id={steps.compiler}
								style={
									show
										? null
										: {
												width: "100vw",
										  }
								}>
								{/* <Compiler /> */}
								<Assignments />
							</section>
						)}
						</div>}
						
				</div>
				<div className={steps.footer}>
				{!st.projects && <div>
					<button
						className={`${steps.next} ${steps.prev}`}
						onClick={() => {
							if (!st?.projects && !st?.Guided || st?.Guided == false) {
								if (subtitle === "Notes") {
									backToPrevious();
								} else if (subtitle === "Video Lecture") {
									dispatch(setSubtitle("Notes"));
								} else if (subtitle === "Take A Quiz") {
									dispatch(setSubtitle("Video Lecture"));
								} else {
									getQuizs();
									dispatch(setSubtitle("Take A Quiz"));
								}
							} else {
								backToPrevious();
							}
						}}>
						<div>
							<img
								width="10px"
								src={arrow}
							/>
							<img
								width="10px"
								src={arrow}
							/>
						</div>
						Previous Topic
					</button>
					</div>
}
					<button
						className={steps.next}
						onClick={() => {
							if (!st?.projects && !st?.Guided || st?.Guided == false) {
								if (subtitle === "Notes") {
									dispatch(setSubtitle("Video Lecture"));
								} else if (subtitle === "Video Lecture") {
									getQuizs();
									dispatch(setSubtitle("Take A Quiz"));
								} else if (subtitle === "Take A Quiz") {
									dispatch(setSubtitle("Your Assignment"));
									getAssignments();
								} else {
									nextTopic();
								}
							} else {
								nextTopic();
							}
						}}>

						{st?.projects || st?.Guided == true
							? "Next Topic"
							: subtitle === "Notes"
							? "Video Lecture"
							: subtitle === "Video Lecture"
							? "Take A Quiz"
							: subtitle === "Take A Quiz"
							? "Your Assignment"
							: "Next Topic"}
						<div>
							<img
								width="10px"
								src={arrow}
							/>
							<img
								width="10px"
								src={arrow}
							/>
						</div>
					</button>
				</div>
			</main>
		</div>
	);
};

export default Steps;
