import React, { useEffect, useRef, useState } from "react";
import certifi from "../icons/certify signed.jpg";
import certificateStyles from "../styles/certificate.module.scss";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { message } from "antd";

const Certificate2 = () => {
	const student = useSelector((state) => state.student.value);
	const [studentName, setStudentName] = useState("");
	const eleRef = useRef();
	const quizprogress = useSelector((state) => state.quizProgress.value);
	const allQuizes = useSelector((state) => state.allQuizes.value);
	const navigate = useNavigate();
	const sectionTitle = useSelector((state) => state.SectionTitle.value);
	const savedProgress = useSelector((state) => state.videoProgress.value);
	const [messageApi, contextHolder] = message.useMessage();

	const error = (erromsg) => {
		messageApi.open({
			type: "error",
			content: erromsg,
		});
	};
	const quizProgressFun = useCallback(() => {
		if (quizprogress.length) {
			const a = quizprogress.filter(
				(e) => e.progress && e.progress.status === true
			).length;
			return a;
		}
	}, [quizprogress]);

	const completedLessons = () => {
		return savedProgress.filter((e) => e.progress >= 80).length;
	};

	function calculateAllLessons() {
		if (sectionTitle.length) {
			let a = [];
			sectionTitle.forEach((e) => {
				if (+e.Day) {
					a.push({
						day: +e.Day,
						data: e,
					});
				}
			});
			const removeDuplicateValues = a?.filter(
				(e, i) => i === a.findIndex((t) => t.day === e.day)
			);

			// completedlessons(removeDuplicateValues);

			const calc = removeDuplicateValues.map((e) =>
				e ? (e.data ? (e.data.Section ? e.data.Section.length : 0) : 0) : 0
			);

			const c = calc.reduce((a, b) => a + b);
			return c;
		}
	}

	const allProgress =
		Math.floor(
			((completedLessons() + quizProgressFun()) /
				(calculateAllLessons() + allQuizes.length)) *
				100
		) >= 90;

	useEffect(() => {
		let timer;
		const completeProgress = () => {
			if (
				Math.floor(
					((completedLessons() + quizProgressFun()) /
						(calculateAllLessons() + allQuizes.length)) *
						100
				) >= 90
			) {
				return;
			} else {
				error("Complete All The Lessons For Certificate");
				timer = setTimeout(() => {
					navigate("/", { replace: true });
				}, 3000);
			}
		};
		completeProgress();

		return () => clearTimeout(timer);
	}, []);
	// const certifi =
	// 	"https://res.cloudinary.com/cliqtick/image/upload/f_auto,q_auto/v1/certificate/techei_panda_certification_fuvh99";

	const handleGeneratePdf = () => {
		html2canvas(eleRef.current).then(function (canvas) {
			const imgData = canvas.toDataURL("image/png");
			const pdf = new jsPDF("l", "px", "a1", true);
			const pdfWidth = pdf.internal.pageSize.getWidth();
			const pdfHeight = pdf.internal.pageSize.getHeight();
			const imgWidth = canvas.width;
			const imgHeight = canvas.height + 50;
			const ratio = Math.min(pdfWidth / imgWidth, pdfHeight / imgHeight);
			const imgX = (pdfWidth - imgWidth * ratio) / 2;
			const imgY = 30;
			pdf.addImage(
				imgData,
				"PNG",
				imgX,
				imgY,
				imgWidth * ratio,
				imgHeight * ratio
			);
			pdf.save("certificate.pdf");
		});
	};
	useEffect(() => {
		if (student && (student.firstName || student.lastName)) {
			student.lastName
				? setStudentName(
						`${student.firstName.toUpperCase()} ${student.lastName.toUpperCase()}`
				  )
				: setStudentName(`${student.firstName.toUpperCase()}`);
		}
		//  else {
		// 	navigate(`/students/${localStorage.getItem("id")}`, { replace: true });
		// }
	}, [student]);
	return (
		<>
			{contextHolder}
			{allProgress && (
				<Button
					onClick={handleGeneratePdf}
					sx={{
						backgroundColor: "#f4b34a",
					}}
					variant="contained">
					download certificate
				</Button>
			)}

			{allProgress && (
				<div
					className={certificateStyles.main}
					ref={eleRef}>
					<img
						src={certifi}
						alt="certificate"
					/>
					<p className={certificateStyles.name}>{studentName}</p>
					<p className={certificateStyles.calender}>2023 - 2024</p>
				</div>
			)}
		</>
	);
};

export default Certificate2;
