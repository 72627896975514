import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";
import { url } from "../../graphql";

const VideoProgressSlice = createSlice({
	name: "Video Progress",
	initialState: {
		value: [],
		status: "",
		progress: "",
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(addProgress.fulfilled, (state, action) => {
			state.status = action.payload;
		});

		builder.addCase(getProgress.fulfilled, (state, action) => {
			state.value = action.payload;
			if (action.payload?.progress) {
				if (state.value.videoId === action.payload.videoId) {
					state.progress = action.payload.progress;
				}
			}
		});
	},
});

export const addProgress = createAsyncThunk("/addProgress", async (args) => {
	const userId = localStorage.getItem("id");
	const token = localStorage.getItem("token");
	const courseId = localStorage.getItem("courseId");

	const progress = args.progress;
	const videoId = args.videoId;
	const videoLanguage = args.videoLang;
	const response = await Axios.post(
		`${url}/addProgress`,
		{
			userId,
			videoId,
			videoLanguage,
			progress,
			courseId,
		},
		{
			headers: {
				Authorization: "Bearer " + token,
			},
		}
	);

	const data = response.data;
	return data;
});

export const getProgress = createAsyncThunk("/getProgress", async (args) => {
	const progressId = args.progressId;
	const token = localStorage.getItem("token");
	try {
		const fetchProgressArr = progressId.map((e) => {
			return Axios.post(
				`${url}/getProgress`,
				{
					progressId: e,
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});

		const data = await Promise.allSettled(fetchProgressArr);
		return data.map((e) => e.value.data);
	} catch (error) {
		console.log(error.message);
	}
});

export default VideoProgressSlice.reducer;
