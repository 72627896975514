import React, { useEffect } from "react";
import plans from "../../styles/profileStyles/choosePlan.module.scss";
import CourseCard from "./CourseCards";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAllCourses } from "../../redux/slices/course";
import { getStudents } from "../../redux/slices/student";

const ShowPlans = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(getAllCourses());
	}, []);
	return (
		<div className={plans.main}>
			<CourseCard />
		</div>
	);
};

export default ShowPlans;
