import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../../graphql";

const PaymentSlice = createSlice({
	name: "Payment",
	initialState: {
		value: [],
		status: "",
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getPayment.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const getPayment = createAsyncThunk("/getPayment", async (args) => {
	try {
		const token = localStorage.getItem("token");
		const ids = args.map((id) => {
			console.log(id);
			return axios.post(
				`${url}/getPaymentID`,
				{ PaymentId: id },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});
		const data = await Promise.allSettled(ids);
		return data.map((e) => e.value.data);
	} catch (error) {
		console.log(error);
	}
});

export default PaymentSlice;
