import React, { Fragment, useEffect } from "react";
import dashboardStyles from "../styles/dashboardStyles.module.scss";
import { useDispatch, useSelector } from "react-redux";
import imageLink from "../icons//thumbnail3.jpg";
import { Link, useNavigate } from "react-router-dom";
import { addCourse } from "../redux/slices/SelectedCourse";
import { getSelectedCourse } from "../redux/slices/course";
import { changeCourseStatus } from "../redux/slices/student";

export default function SubscribedDashboard() {
	const course = useSelector((state) => state.course.value);
	const student = useSelector((state) => state.student.value);
	const studentId = localStorage.getItem("id")
    const selectedCourseId = useSelector(
		(state) => state.course?.selectedCourse?._id
	);

	const authToken = localStorage.getItem("token");
	const navigate = useNavigate();
	const dispatch = useDispatch();
	useEffect(() => {
		if (!authToken || authToken == "undefined") {

			navigate("/", { replace: true });
		}
	}, [authToken]);

	const startDate = new Date(student?.courseEnrolledData?.find(e => e.courseId == selectedCourseId)?.courseDate).getTime();
	const currentDate = new Date().getTime() ;

	// const myFun = ()=>{

	// 	dispatch(changeCourseStatus({
	// 		courseEnrolledD:selectedCourseId,
	// 		date:new Date().toString()
	// 	})).then(()=>{
	
	// 		navigate("/MyDashboard",{replace:true})

	// 	})
	// }

	// window.onload = () =>{
	// 	// console.log(new Date("Fri Oct 10 2023 12:12:53 GMT+0530 (India Standard Time)").getTime() - new Date().getTime() / (3600000 *24))

	// 	Math.round(Math.abs(startDate - currentDate)) / (3600000 *24) >= 365  ? myFun() :console.log("hello world")
	// }

	return (
		<div className={dashboardStyles.container}>
			{course &&
				course.map((e, i) => {
					return (
						<Fragment key={i}>
							<div className={dashboardStyles.courseDiv}>
								<div className={dashboardStyles.CourseName}>
									<div className={dashboardStyles.courseTitle}>
										<div
											style={{
												width: "50%",
												height: "100%",
												backgroundImage: `url(${imageLink})`,
												backgroundSize: "contain",
												backgroundPosition: "top center",
												backgroundRepeat: "no-repeat",
												borderRadius: "0.5rem",
											}}></div>
										<div className={dashboardStyles.buttons}>
											<div>
												<h1>{e.course_name?.toUpperCase()}</h1>

												<p
												// style={{
												// 	width: "100%",
												// 	fontSize: "16px",
												// 	fontWeight: "300",
												// 	marginBottom: "1rem",
												// 	marginTop: "0.5rem",
												// }}
												>
													With the help of a variety of projects, assignments
													and challenges, the program teaches students how to
													create high-quality dynamic websites.
												</p>
											</div>
											{/* <Link to="/mentors">
											<button
												disabled
												className={dashboardStyles.mentor}>
												GET YOUR MENTOR NOW
											</button>
											</Link> */}

											<button
												className={dashboardStyles.startProgram}
												disabled={studentId === "633d8c8e8f3bcd7e94612929"|| studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b" ||studentId === "6513e2a7dc706cf1e7a23b0a" ? false :Math.round(Math.abs(currentDate )/(3600000 *24) ) >= Math.round(Math.abs(new Date("Fri Oct 10 2023 12:12:53 GMT+0530 (India Standard Time)").getTime()) / ((3600000 *24))) ? false :true}
												onClick={() => {
													if(student?.blocked == true){
														alert("Access to the course has been suspended due to a payment matter. Please reach out to our team for assistance.")
													}else{
														localStorage.setItem("courseId", e._id);
														localStorage.setItem("courseName", e.course_name);
														dispatch(addCourse(e._id));
														dispatch(getSelectedCourse(e._id)).catch((err) =>
															console.log(err.message)
														);
														// Math.round(Math.abs(startDate - currentDate)) / (3600000 *24) >= 365  ||student?.courseEnrolledData?.find(e => e.courseId == selectedCourseId)?.active ===false ?myFun():
														navigate(`/Module/${e._id}`, {
															replace: true,
														}) 
													}
											
												}}
												// style={{
												// 	border: "none",
												// 	outline: "none",
												// 	textDecoration: "none",
												// 	color: "white",
												// 	fontWeight: "500",
												// 	textTransform: "uppercase",

												// }}
											>
											{
											// Math.round(Math.abs(startDate - currentDate)) / (3600000 *24) >= 365 ||student?.courseEnrolledData?.find(e => e.courseId == selectedCourseId)?.active ===false? "Course Expired":
											student && student.course && student.course.length &&
											studentId === "633d8c8e8f3bcd7e94612929"|| studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b" ||studentId === "6513e2a7dc706cf1e7a23b0a" ? "Continue"	: Math.round(Math.abs(currentDate )/(3600000 *24) ) > Math.round(Math.abs(new Date("Fri Oct 10 2023 12:12:53 GMT+0530 (India Standard Time)").getTime()) / ((3600000 *24))) ? "Continue" :"Course will be unlocked in Oct 10"
													}
											</button>
										</div>
									</div>
								</div>
								{/* <div className={dashboardStyles.CourseProgress}>
									<div
										className={dashboardStyles.progressLevel}
										style={{
											width: "100%",
											height: "100%",
											backgroundImage: `url(${imageLink})`,
											backgroundSize: "cover",
											backgroundPosition: "center",
											backgroundRepeat: "no-repeat",
										}}></div>
								</div> */}
							</div>
						</Fragment>
					);
				})}
		</div>
	);
}
