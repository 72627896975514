import { createSlice } from "@reduxjs/toolkit";

const LanguageSlice = createSlice({
	name: "Language",
	initialState: {
		value: "English",
		videoId: "",
	},
	reducers: {
		setLanguage: (state, action) => {
			state.value = action.payload.language;
			state.videoId = action.payload.videoId;
		},
	},
});

export const { setLanguage } = LanguageSlice.actions;

export default LanguageSlice;
