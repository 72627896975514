import { createSlice } from "@reduxjs/toolkit";

const markCompleSlice = createSlice({
	name: "Mark Complete",
	initialState: {
		value: [],
	},
	reducers: {
		setMarkComple: (state, action) => {
			console.log(action.payload);
			state.value = action.payload;
		},
	},
});

export const { setMarkComple } = markCompleSlice.actions;

export default markCompleSlice.reducer;
