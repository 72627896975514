import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const SectionTitleSlice = createSlice({
	name: "SectionTitle",
	initialState: {
		value: [],
		Day: {},
		status: null,
	},
	extraReducers: (builder) => {
		builder.addCase(getSectionTitle.fulfilled, (state, action) => {
			state.value = action.payload;

			state.status = null;
		});
		builder.addCase(getSectionTitle.pending, (state, action) => {
			state.status = "Loading...";
		});
		// builder.addCase(getSectionTitleByDay.fulfilled, (state, action) => {
		// 	state.Day = action.payload;
		// });
	},
});

export const getSectionTitle = createAsyncThunk(
	"/getSectionTitle",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			const test = args.sectionTitle;
		
			const ids = test.map((id) => {
				return axios.post(
					`${url}/getOneSectionTitle`,
					{ sectionTitleId: id },
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
			});
			
			const data = await Promise.allSettled(ids);
			return data.map((e) => e.value.data).sort((a,b) => a.Day-b.Day);
		} catch (error) {
			return new Error(error);
		}
	}
);

export const getSectionTitleByDay = createAsyncThunk(
	"/getSectionTitleByday",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			// const day = args.Day;
			// const moduleName = args.module
			console.log(args.Day);

			const response = await axios.post(
				`${url}/getSectionByDay`,
				{ Day: args.Day ? args.Day : "1" },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);

			return response.data;
		} catch (error) {
			return new Error(error);
		}
	}
);

export default SectionTitleSlice;
