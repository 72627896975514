import React from "react";
import entStyles from "../../styles/profileStyles/entrance.module.scss";
import pandaLogo from "../../icons/techiePandaLogo2.png";

const EnterancePage = () => {
	return (
		<div className={entStyles.main}>
			<h1>Welcome to Your Dashboard.</h1>
			<p>select the edit tab for start editing your profile</p>
			<img
				src={pandaLogo}
				alt="panda logo"
			/>
		</div>
	);
};

export default EnterancePage;
