import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "../../graphql";
import { controller } from "../../Utils/abortContoller";

const ContentSlice = createSlice({
	name: "Content",
	initialState: {
		value: [],
		status: "",
		error: "",
	},
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(getContent.fulfilled, (state, action) => {
				state.value = action.payload;
				state.status = null;
			})
			.addCase(getContent.pending, (state, action) => {
				state.status = "loading";
			})
			.addCase(getContent.rejected, (state, action) => {
				state.status = "failed";
				state.error = action.error.message;
			});
	},
});

export const getContent = createAsyncThunk("/getContent", async (args) => {
	try {
		
		const token = localStorage.getItem("token");

		let small = ""
		if(args?.SectionTitle?.split(" ")?.join("").length > 0){
			
			if(args?.SectionTitle?.split(" ")?.join("") == "HTML" ){

				small = args?.SectionTitle?.split(" ")?.join("")
			}
			else if(args?.SectionTitle?.split(" ")?.join("") == "CSS" ){

				small = args?.SectionTitle?.split(" ")?.join("")
			} 
			else if(args?.SectionTitle?.split(" ")?.join("") == "BOOTSTRAP" ){

				small = args?.SectionTitle?.split(" ")?.join("")
			} 
			else if(args?.SectionTitle?.split(" ")?.join("") == "JS"){

				small = args?.SectionTitle?.split(" ")?.join("")
			}
			else{

				small = "Content"
	
			}
		}

		controller.contentController = new AbortController();


			const response = await axios.post(
				`${url}/getOne${small}`,
				{ Id: args.content },
				{
					signal: controller.signal,
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
			return response.data;

	} catch (error) {
		return new Error(error);
	}
});

export default ContentSlice;
