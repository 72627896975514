import React from "react";
import resumeStyles from "../../styles/resumeStyles/Resume.module.scss";

const Resume = () => {
	return (
		<div className={resumeStyles.main}>
			<section>Editor</section>
			<section>File</section>
		</div>
	);
};

export default Resume;
