import { createSlice } from "@reduxjs/toolkit";

const SelectedSec = createSlice({
	name: "Selected",
	initialState: {
		value: {},
		index: 1,
	},
	reducers: {
		addSection: (state, action) => {
			state.value = action.payload.value;
			state.index = action.payload.index;
		},
	},
});

export const { addSection } = SelectedSec.actions;

export default SelectedSec.reducer;
