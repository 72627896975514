import React, { useState, useEffect } from "react";
import styles from "../styles/signup.module.scss";
import logo from "../icons/techiePandaLogo.png";
import { useSelector, useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import OtpInput from "react18-input-otp";
import { AddOtp } from "../redux/slices/studentprofile";
import { OtpLogin } from "../redux/slices/studentprofile";
import { message } from "antd";
import {
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
	TextField,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { PasswordLogin } from "../redux/slices/student";
import ForgotPassword from "../components/studentProfile Components/forgotPasswordComp";
import { useCallback } from "react";

export default function Signup({ getOS }) {
	const navigate = useNavigate();
	let authToken = localStorage.getItem("token");
	const [messageApi, contextHolder] = message.useMessage();
	const [LoginForm, setLoginForm] = useState(false);

	const [showPassword, setShowPassword] = React.useState(false);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const [authMessage, setAuthMessage] = useState();
	const [noAuthMessage, setNoAuthMessage] = useState();
	const [values, setValues] = useState({
		email: "",
		phone: "",
		otp: "",
		password: "",
		userType: "student",
	});
	const [password, setPass] = useState("");
	const auth = useSelector((state) => state.AddOtp.value);
	const notAuth = useSelector((state) => state.otpLogin.value);

	const [sh, setSh] = useState(false);
	const [OOtp, setOtp] = useState("");
	const dispatch = useDispatch();
	const errorMessage = useSelector((state) => state.student.error);

	const navigation = useCallback(() => {
		if (authToken && authToken !== "undefined") {
			navigate("/MyDashboard", {
				replace: true,
			});
		} else {
			navigate("/", { replace: true });
		}
	}, [authToken]);

	useEffect(() => {
		navigation();
	}, [navigation]);

	// const { login: login } = useAuth();
	useEffect(() => {
		// let a = "";
		// let b = "";
		if (auth) {
			if (auth.data) {
				if (auth.data) {
					setAuthMessage(auth.data);
					// a = auth.data;
				} else {
					setAuthMessage("User not registered");
					// a = "User not registered";
					document.querySelector(".styylle").style = "color:red;";
				}
			}
		}
		if (notAuth) {
			if (notAuth.message) {
				setNoAuthMessage(notAuth.message);
				// b = notAuth.message;
			}
		}
	}, [auth, notAuth]);

	// console.log(values.password);
	const toggle = () => {
		setSh(true);
	};
	const onChange = (event) => {
		let key = "email";
		// var validateMail = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
		// var validateNumber = /[0-9]/;
		let value = event.target.value;
		// if (value.match(validateMail)) {
		// 	key = "email";
		// }
		// if (value.match(validateNumber)) {
		// 	key = "number";
		// }
		// console.log(key)
		setValues({ ...values, [key]: value });
	};

	const otpGenerate = async (event) => {
		event.preventDefault();

		dispatch(
			AddOtp({
				email: values.email,
				phone: values.phone,
			})
		);
		toggle();
	};

	const handleChange = (enteredOtp) => {
		setOtp(enteredOtp);
	};

	const SubmitHandler = async (event) => {
		event.preventDefault();
		try {
			// const fetchData = await fetch("https://jsonip.com", { mode: "cors" });
			// const res = await fetchData.json();
			// const ip = await res.ip;
			// console.log(ip);
			// if (ip) {
			dispatch(
				OtpLogin({
					email: values.email,
					phone: values.phone,
					otp: OOtp,
					browser: window.navigator.userAgent,
					OS: getOS().os,
				})
			);
			// } else {
			// 	throw new Error("ip address not available");
			// }
		} catch (error) {
			console.log(error.message);
		}
	};

	const toggleButton = () => {
		if (LoginForm === false) setLoginForm(true);
		else setLoginForm(false);
	};

	/////////////////////////////////// Password Login Code ///////////////////////////////////////////////

	const SubmitLogin = () => {
		const email = values.email;
		dispatch(
			PasswordLogin({
				email: email,
				password: password,
				browser: window.navigator.userAgent,
				OS: getOS().os,
			})
		)
			.then((res) => (res.payload.token ? window.location.reload() : null))
			.catch((err) =>
				messageApi.open({
					type: "warning",
					content: err.message,
				})
			);
		if (errorMessage) {
			messageApi.open({
				type: "warning",
				content: errorMessage,
			});
		}
	};

	// const ErrorMessage = useCallback(() => {
	// 	if (errorMessage) {
	// 		messageApi.open({
	// 			type: "warning",
	// 			content: errorMessage,
	// 		});
	// 	}
	// }, [errorMessage, messageApi]);

	useEffect(() => {
		if (errorMessage) {
			messageApi.open({
				type: "warning",
				content: errorMessage,
			});
		}
	}, [errorMessage, messageApi]);
	/////////////////////////////////// Password Login Code ///////////////////////////////////////////////

	/////////////////////////////////// Forgot Password ///////////////////////////////////////////////
	const [forgot, setForgot] = useState(false);

	const toggleForgotComponent = () => {
		if (forgot === false) setForgot(true);
		else setForgot(false);
	};

	/////////////////////////////////// Forgot Password ///////////////////////////////////////////////
	return (
		<>
			{contextHolder}
			<div className={styles.signContainer}>
				<div className={forgot ? styles.logIn : styles.signLeft}>
					<img
						src={logo}
						alt="logo"
						onClick={() => {}}
						className={styles.logo}
					/>
					<div className={styles.signText}>Sign in to continue learning</div>
					<p
						style={
							!LoginForm
								? { display: "block", color: "green", fontWeight: "600" }
								: { display: "none" }
						}
						className="styylle">
						{authMessage}
					</p>
					<form
						className={styles.form}
						onSubmit={SubmitHandler}>
						<TextField
							className={styles.mailInput}
							// id="outlined-password-input"
							label="Email"
							autoComplete="current-password"
							onChange={onChange}
							name="Email"
						/>
						<FormControl
							sx={{ m: 0, width: "100%", marginTop: "2rem" }}
							variant="outlined"
							style={
								LoginForm
									? { display: "block", width: "100%" }
									: { display: "none" }
							}>
							<InputLabel htmlFor="outlined-adornment-password">
								Password
							</InputLabel>
							<OutlinedInput
								id="outlined-adornment-password"
								type={showPassword ? "text" : "password"}
								onChange={(e) => setPass(e.target.value)}
								name="password"
								style={{ width: "100%" }}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end">
											{showPassword ? <Visibility /> : <VisibilityOff />}
										</IconButton>
									</InputAdornment>
								}
								label="Password"
							/>
						</FormControl>
						<div
							className={
								!sh ? styles.logIn : LoginForm ? styles.logIn : styles.logInput
							}>
							<OtpInput
								className={styles.OtpInput}
								value={OOtp}
								onChange={handleChange}
								numInputs={4}
							/>
						</div>
						<div className={styles.forgetDiv}>
							<div
								className={styles.passLogin}
								onClick={toggleButton}>
								{!LoginForm ? "Login with Password" : "Login with OTP"}
							</div>
							<div
								className={styles.forgot}
								onClick={toggleForgotComponent}>
								Forgot password..?
							</div>
						</div>
						<div
							style={
								!LoginForm
									? { display: "block", margin: "0 auto" }
									: { display: "none" }
							}>
							<div
								className={sh ? styles.logIn : styles.otpLogin}
								onClick={otpGenerate}
								id="buttoon">
								Login with OTP
							</div>

							<div
								className={!sh ? styles.logIn : styles.otpLogin}
								onClick={SubmitHandler}
								id="buttoonn">
								Submit
							</div>
						</div>
						<div
							style={
								LoginForm
									? { display: "block", margin: "0 auto" }
									: { display: "none" }
							}>
							<div
								className={styles.otpLogin}
								onClick={SubmitLogin}>
								Login
							</div>
						</div>
						<Link
							to="/createAccount"
							style={{ textDecoration: "none" }}>
							<div className={styles.createAc}>Create Account</div>
						</Link>

						<p
							style={{
								fontSize: "1rem",
								marginTop: "1rem",
								textAlign: "center",
								color: "red",
							}}>
							{noAuthMessage}
						</p>

						{/* <div className={styles.signUp}>
            <span>Don't have an account ? </span>
            <a href="#" className={styles.sign_up}>
              Sign Up
            </a>
          </div> */}
					</form>
				</div>

				{/* Forgot Password component */}
				<div className={forgot ? styles.signLeft : styles.logIn}>
					<ForgotPassword setForgot={setForgot} />
				</div>
				{/* Forgot Password component */}

				<div className={styles.signRight}>
					<div className={styles.layer}>
						<h2 className={styles.heading}>
							Methodised to Land Your Dream Job
						</h2>
						<div className={styles.rightContainer}>
							<div>
								<span className={styles.boldText}>60+</span>
								<span className={styles.smallText}>
									Guided &amp; Independent Project
								</span>
							</div>
							<div>
								<span className={styles.boldText}>300+</span>
								<span className={styles.smallText}>
									Assignments &amp; Challenges
								</span>
							</div>
							<div>
								<span className={styles.boldText}>8</span>
								<span className={styles.smallText}>
									Hrs of Q/A Session Everyday
								</span>
							</div>
							<div>
								<span className={styles.boldText}>3</span>
								<span className={styles.smallText}>Free Mock Interviews</span>
							</div>
							<div>
								<span className={styles.boldText}>200+</span>
								<span className={styles.smallText}>Hiring Partners</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
