import React, { useState, useRef } from "react";
import quizstyles from "../styles/quiz.module.scss";
import { useSelector, useDispatch } from "react-redux";
import { addQuizProgress } from "../redux/slices/quiz";
import { getStudents } from "../redux/slices/student";
import { message } from "antd";
import { useEffect } from "react";

const Quiz = ({ show }) => {
	const quiz = useSelector((state) => state.quizes.value);
	const quizprogress = useSelector((state) => state.quizProgress.value);
	const [ans, setAnswer] = useState({});
	const [answersObj, setAnswersObj] = useState({});
	const dispatch = useDispatch();
	const selectRef = useRef([]);
	const [messageApi, contextHolder] = message.useMessage();
	const selectedCourse = useSelector((state) => state.course.selectedCourse);

	const selectAnswer = (event, e, index) => {
		if (selectRef.current[index]) {
			if (selectRef.current[index].children.length) {
				for (let f of selectRef.current[index].children) {
					if (
						f.tagName !== "P" &&
						f.getAttribute("data") &&
						f.className == "change"
					) {
						f.style.backgroundColor = "white";
						if (event.target.getAttribute("data"))
							event.target.style.backgroundColor = "#f4b34a98";
					}
					if (event.target.getAttribute("data")) {
						setAnswer({ ...ans, [index]: event.target.getAttribute("data") });
						setAnswersObj({ ...answersObj, [index]: e.answer });
					}
				}
			}
		}
	};

	const handleSubmit = (event, e, index) => {
		event.preventDefault();
		if (ans[index]) {
			if (answersObj[index] == ans[index]) {
				for (let ele of selectRef.current[index].children) {
					if (ele.getAttribute("data") == answersObj[index]) {
						ele.style.backgroundColor = "#5D9C59"; //green
					}
				}
				dispatch(
					addQuizProgress({
						quizId: e._id,
						courseId: selectedCourse._id,
						correctAnswer: answersObj[index],
						selectedAnswer: ans[index],
						status: true,
					})
				).then(() => {
					dispatch(getStudents());
				});
			} else {
				for (let ele of selectRef.current[index].children) {
					if (ele.getAttribute("data") == ans[index]) {
						ele.style.backgroundColor = "#DF2E38"; // red
					}
					if (ele.getAttribute("data") == answersObj[index]) {
						ele.style.backgroundColor = "#5D9C59"; //green
					}
				}
				dispatch(
					addQuizProgress({
						quizId: e._id,
						courseId: selectedCourse._id,
						correctAnswer: answersObj[index],
						selectedAnswer: ans[index],
						status: false,
					})
				).then(() => {
					dispatch(getStudents());
				});

				event.target.children[event.target.children.length - 1].disabled = true;
			}
		} else {
			messageApi.open({
				type: "error",
				content: "Select an answer and then submit",
			});
		}
	};

	const quizProgressFun = (event) => {
		quiz.length &&
			quiz.forEach((e, i) => {
				let progress = quizprogress.find((f) => f.progress.id == e._id);

				if (quizprogress && progress && e._id == progress.progress.id) {
					if (selectRef.current.length) {
						if (selectRef.current[i].children.length) {
							for (let ele of selectRef.current[i].children) {
								if (ele.tagName !== "P") {
									if (
										progress.progress.correctAnswer == ele.getAttribute("data")
									) {
										ele.style.backgroundColor = "#5D9C59"; //green
									}
									if (
										progress.progress.selectedAnswer == ele.getAttribute("data")
									) {
										ele.style.backgroundColor = "#DF2E38"; // red
									}
									if (
										progress.progress.selectedAnswer ==
											ele.getAttribute("data") &&
										progress.progress.correctAnswer == ele.getAttribute("data")
									) {
										ele.style.backgroundColor = "#5D9C59"; //green
									}
								}
							}
						}
					}
				} else {
					if (
						selectRef.current.length &&
						selectRef.current[i].children.length
					) {
						for (let ele of selectRef.current[i].children) {
							if (ele.tagName !== "P") {
								if (event && event.target.className == "change") {
									event.target.style.backgroundColor = "#f4b34a98";
								}
							}
						}
					}
				}
			});
	};

	useEffect(() => {
		if (selectRef.current.length) {
			selectRef.current.forEach((e) => {
				if (e && e.children) {
					for (let a of e.children) {
						a.style.background = "none";
					}
				}
			});
		}
		quizProgressFun();
	}, [quiz, quizprogress]);

	return (
		<>
			{contextHolder}
			<div
				className={
					show ? quizstyles.main : `${quizstyles.main} ${quizstyles.nomain}`
				}>
				{quiz.length ? (
					quiz.map((e, i) => {
						return (
							<form
								key={i}
								onSubmit={(event) => handleSubmit(event, e, i)}>
								{i < 1 && <h1>{e.title} </h1>}
								<h3>Quiz: {i + 1}</h3>

								<div
									className={quizstyles.quizBody}
									ref={(el) => (selectRef.current[i] = el)}
									onClick={(event) =>
										quizprogress.find((f) => f.progress.id == e._id)
											? null
											: selectAnswer(event, e, i)
									}>
									<p style={{ fontWeight: "600" }}>{e.question}</p>
									<div
										className="change"
										data={e.option1}>
										A. {e.option1}
									</div>
									<div
										className="change"
										data={e.option2}>
										B. {e.option2}
									</div>
									<div
										className="change"
										data={e.option3}>
										C. {e.option3}
									</div>
									<div
										className="change"
										data={e.option4}>
										D. {e.option4}
									</div>
								</div>
								<button
									disabled={
										quizprogress.find((f) => f.progress.id == e._id)
											? true
											: false
									}
									type="submit">
									Submit Answer
								</button>
							</form>
						);
					})
				) : (
					<>{<h1>Today No Quiz😉</h1>}</>
				)}
			</div>
		</>
	);
};

export default Quiz;
