import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ProfileSidepanel from "../components/studentProfile Components/ProfileSidepanel";
import ProfileBody from "../components/studentProfile Components/ProfileBody";
import stProfileStyles from "../styles/profileStyles/StudentsProfile.module.scss";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import EditIcon from "@mui/icons-material/Edit";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { useNavigate } from "react-router-dom";
import PasswordIcon from "@mui/icons-material/Password";

const StudentsProfile = () => {
	const navigate = useNavigate();
	let token = localStorage.getItem("token");
	let id = localStorage.getItem("id");

	useEffect(() => {
		if (localStorage.getItem("token") && localStorage.getItem("id")) {
			return;
		} else {
			navigate("/", { replace: true });
		}
	}, [token, id, navigate]);
	const student = useSelector((state) => state.student.value);
	const [selectTab, setSelectTab] = useState("");

	const tabs = [
		<p>
			<EditIcon /> <span>Edit Profile</span>{" "}
		</p>,
		<p>
			<PasswordIcon /> <span>Change Password</span>{" "}
		</p>,
		<p>
			<LibraryBooksIcon /> <span>Invoices</span>{" "}
		</p>,
		<p>
			<PictureAsPdfIcon /> <span>Make Your Resume</span>{" "}
		</p>,
	];

	return (
		<div className={stProfileStyles.main}>
			<ProfileSidepanel
				tabs={tabs}
				selectTab={selectTab}
				setSelectTab={setSelectTab}
			/>
			<ProfileBody
				student={student}
				selectTab={selectTab}
			/>
		</div>
	);
};

export default StudentsProfile;
