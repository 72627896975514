import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const ModulesSlice = createSlice({
	name: "Modules",
	initialState: {
		value: [],
		oneModule : {}
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getModules.fulfilled, (state, action) => {
			state.value =action.payload;
		});
		builder.addCase(getModule.fulfilled, (state, action) => {
			state.oneModule = action.payload;
		});
	},
});

export const getModules = createAsyncThunk("/getModules", async (args) => {
	try {
		const token = localStorage.getItem("token");

			const ids = args.map((id) => {
				return axios.post(
					`${url}/getOneModule`,
					{ moduleId: id },
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				);
			});
			const data = await Promise.allSettled(ids);
			return data.map((e) => e.value.data);
		

	} catch (error) {
		return new Error(error);
	}
});


export const getModule = createAsyncThunk("/getModule", async (args) => {
	try {
		const token = localStorage.getItem("token");
	
				const data = await axios.post(
					`${url}/getOneModule`,
					{ moduleId: args },
					{
						headers: {
							Authorization: "Bearer " + token,
						},
					}
				)
				return data.data
			}catch(err){
				return new Error(err);
	}
})
export default ModulesSlice;
