import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const projectSlice = createSlice({
	name: "project",
	initialState: {
		value: [],
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getProject.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const getProject = createAsyncThunk("/getProject", async (args) => {
	try {
		const token = localStorage.getItem("token");
		const fetchprojectsArr = args.map((e) => {
			return axios.post(
				`${url}/getProject`,
				{ projectId: e },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});
		const data = await Promise.allSettled(fetchprojectsArr);
		return data.map((e) => e.value.data);
	} catch (error) {
		return new Error(error.message);
	}
});

export default projectSlice;
