import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const quizSlice = createSlice({
	name: "Quiz",
	initialState: {
		value: [],
	},
	reducers: {
		emptyQuiz: (state, action) => {
			state.value = [];
		},
	},
	extraReducers: (builder) => {
		builder.addCase(getQuiz.fulfilled, (state, action) => {
			state.value = action.payload;
		});
	},
});

export const getQuiz = createAsyncThunk("/getQuiz", async (args) => {
	try {
		const token = localStorage.getItem("token");
		const ids = args?.map((id) => {
			return axios.post(
				`${url}/getQuiz`,
				{ QuizId: id },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});
		const data = await Promise.allSettled(ids);
		return data.map((e) => e.value.data);
	} catch (error) {
		return new Error(error);
	}
});

export const addQuizProgress = createAsyncThunk(
	"/addQuizProgress",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			const courseId = localStorage.getItem("courseId");
			const type = "Quiz";
			const id = localStorage.id;
			await axios.post(
				`${url}/addStudentProgress`,
				{
					type: type,
					userId: id,
					progress: {
						id: args.quizId,
						courseId: args.courseId ? args.courseId : courseId,
						correctAnswer: args.correctAnswer,
						selectedAnswer: args.selectedAnswer,
						status: args.status,
					},
				},
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		} catch (error) {
			console.log("error in addQuizProgress", error.message);
		}
	}
);

export const { emptyQuiz } = quizSlice.actions;

export default quizSlice;
