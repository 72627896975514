import React from "react";
import TimeTableBody from "../components/timeTableBody";
import ModulePage from "../components/modulePage";
export default function Timetable({
	openCal,
	setOpenCalender,
	select,
	setSelected,
	selectedDate,
	setSelectedDate,
}) {
	return (

		<ModulePage
			openCal={openCal}
			setOpenCalender={setOpenCalender}
			select={select}
			setSelected={setSelected}
			selectedDate={selectedDate}
			setSelectedDate={setSelectedDate}
		/>
	);
}
