import { configureStore } from "@reduxjs/toolkit";
import CourseSlice from "./slices/course";
import ModulesSlice from "./slices/modules";
import SectionSlice from "./slices/section";
import SectionTitleSlice from "./slices/sectionTite";
import StudentSlice from "./slices/student";
import studenttSlice from "./slices/studentprofile";
import ContentSlice from "./slices/content";
import titleAndSubTitle from "./slices/titleAndSubTitle";
import quizSlice from "./slices/quiz";
import SelectedSection from "./slices/SelectedSection";
import videoprogressSlice from "./slices/videoprogressSlice";
import sectionProgressSlice from "./slices/sectionProgressSlice";
import markAsCompleteSlice from "./slices/markAsCompleteSlice";
import PaymentSlice from "./slices/payment";
import SelectedCourse from "./slices/SelectedCourse";
import createStudentSlice from "./slices/createAccount";
import assignmentSlice from "./slices/assignmentSlice";
import quizProgrssSlice from "./slices/quizProgressSlice";
import allQuizSlice from "./slices/getallQuizesSlices";
import projectSlice from "./slices/projectsSlice";
import LanguageSlice from "./slices/setlanguageSlice";

const store = configureStore({
	reducer: {
		student: StudentSlice.reducer,
		course: CourseSlice.reducer,
		module: ModulesSlice.reducer,
		SectionTitle: SectionTitleSlice.reducer,
		sections: SectionSlice.reducer,
		AddOtp: studenttSlice.reducer,
		otpLogin: studenttSlice.reducer,
		content: ContentSlice.reducer,
		payment: PaymentSlice.reducer,
		titles: titleAndSubTitle,
		quizes: quizSlice.reducer,
		allQuizes: allQuizSlice.reducer,
		quizProgress: quizProgrssSlice.reducer,
		selectSelection: SelectedSection,
		videoProgress: videoprogressSlice,
		sectionProgress: sectionProgressSlice,
		markCompleted: markAsCompleteSlice,
		selectedCourse: SelectedCourse,
		createStudent: createStudentSlice.reducer,
		assignment: assignmentSlice,
		project: projectSlice.reducer,
		language: LanguageSlice.reducer,
	},
});

export default store;
