import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getSection } from "../redux/slices/section";
import bodystyles from "../styles/body.module.scss";
import arrow from "../icons/ArrowIcon.png";
import { Collapse, message } from "antd";
import { useNavigate } from "react-router-dom";
import Steps from "./Steps";
import { getContent } from "../redux/slices/content";
import { setTitle, setSubtitle } from "../redux/slices/titleAndSubTitle";
import lockYellow from "../icons/lock_yellow.png";
import tick from "../icons/tick.png";
import unlockGold from "../media/unlocked.png";
import {
	getStudents,
	updateStudentProgressForDay,
	updateStudentProgressForDayTelugu,
} from "../redux/slices/student";
import { getProject } from "../redux/slices/projectsSlice";
import Loading from "./LoadingScreen";
import { controller } from "../Utils/abortContoller";
 

const { Panel } = Collapse;

export default function Body(props) {

	const studentId = localStorage.getItem("id")

	const dispatch = useDispatch();
	const [show, setShow] = useState(true);
	const [creds, setVideoCreds] = useState({});
	const [sec, setSec] = useState("");
	const navigate = useNavigate();
	const sections = useSelector((state) => state.sections.value);
	const projects = useSelector((state) => state.project.value);
	const sectionTitle = useSelector((state) => state.SectionTitle.value);
	// const st = useSelector((state) => state.SectionTitle.Day);
	const [st, setSt] = useState({});
	let authToken = localStorage.getItem("token");
	const days = localStorage.getItem("day");
	const eleRef = useRef();
	const selectSec = useSelector((state) => state.selectSelection.value);
	const [defaultsec, setDefaultSec] = useState({});
	const savedProgress = useSelector((state) => state.videoProgress.value);
	const tickRef = useRef([]);
	const title = useSelector((state) => state.titles.title);
	const projectArr = useSelector((state) => state.project.value);

	const [linkTitle, setLinkTitle] = useState([]);
	const sectionTitleStatus = useSelector((state) => state.SectionTitle.status);
	const language = useSelector((state) => state.language.value);
	const student = useSelector((state) => state.student.value);
	const [messageApi, contextHolder] = message.useMessage();
	const selectedCourse = useSelector((state) => state.course.selectedCourse);
	
	useEffect(() => {
		if (!authToken) {
			navigate("/", { replace: true });
		}
	}, [authToken, navigate]);

	useEffect(()=>{
		if(projectArr){
			if(projectArr.length) dispatch(setTitle(projectArr[0].title))
		}
	},[projectArr])
	useEffect(() => {
		const topic = localStorage.getItem("topic");
		for (let i of sections) {
			if (i.title === title) {
				setDefaultSec(i);
			}
		}
	}, [sections]);

	const toggle = () => {
		setShow(!show);
	};

	const setActive = () => {
		if (eleRef) {
			const currentRef = eleRef?.current;
			const children = currentRef?.children;
			const index = +localStorage.getItem("index");
			if (children[index]) {
				for (let e of children) {
					e.classList?.remove(bodystyles.active);

				}
				children[index].classList.add(bodystyles.active);
				if(linkTitle[linkTitle?.length -1]._id ==children[index].lastChild.lastChild.lastChild.alt ) localStorage.setItem("sId", children[index].lastChild.lastChild.lastChild.alt) 
				else localStorage.setItem("sId",null)
				if (index === 0) {
					if (!st?.projects) {
						let progressIndex0 = savedProgress.find((e) => {
							return language === "Telugu"
								? e.videoId === sections[index].teluguVideoId
								: e.videoId === sections[index].videoId;
						});
						children[index].lastChild.lastChild.lastChild.src =
							progressIndex0 && progressIndex0.progress >= 80
								? tick
								: unlockGold;
						children[index].lastChild.lastChild.lastChild.setAttribute(
							"width",
							"60%"
						);
						children[index].lastChild.lastChild.lastChild.setAttribute(
							"height",
							"60%"
						);
					}
				}
			}
		}
	};

	useEffect(() => {
		if (st) if (st.Section) dispatch(getSection(st.Section));

		setSec(st);
	}, [days, sec, creds, st]);

	useEffect(() => {
		if (days) {
			// dispatch(getSectionTitleByDay({ Day: days }));
			const stt = sectionTitle?.find((e) => e.Day === days);
			setSt(stt);
		}
	}, [days, sectionTitle]);

	useEffect(() => {
		setActive();

		if (st?.courseTitle) {
			let input;
			if (selectSec?.title && !creds?.title) {
				input = selectSec;
			} else if (creds?.title) {
				input = creds;
			} else {
				input = defaultsec;
			}

			if (input) {
				dispatch(
					getContent({
						SectionTitle: st?.courseTitle,
						content: input.content,
					})
				);
				dispatch(setTitle(input.title));
				dispatch(setSubtitle("Notes"));
			}
		}
	}, [st, defaultsec, creds, selectSec, sections, days, sec,title]);

	const [b, setB] = useState();
	const sectionTitleProgress = (section) => {
		if (!st?.projects) {
			let a = [];
			if (section) {
				for (let i of section) {
					a.push(
						savedProgress?.find((e) =>
							language === "Telugu"
								? e.videoId === i.teluguVideoId
								: i.videoId === e.videoId
						)
					);
				}

				if (a.length) {
					a.forEach((e) => {
						if (e) {
							let bb = a?.filter((f) => (f ? f.progress > 80 : null)).length;
							setB(bb);
						}
					});
				}
			}
		}
	};

	const openNextSection = (tickRef) => {
		if (!st?.projects) {
			const currentRef = tickRef?.current;
			const newRefs = currentRef?.filter((e) => {
				return e && e;
			});

			const filter = newRefs?.filter((e) => e.getAttribute("src") === `${tick}`);
			if (newRefs.length !== filter.length) {
				newRefs[filter.length].setAttribute("src", unlockGold);
				newRefs[
					filter.length
				].parentNode.parentNode.parentNode.disabled = false;
				newRefs[filter.length].setAttribute("width", "60%");
				newRefs[filter.length].setAttribute("height", "60%");
				newRefs[filter.length].parentNode.parentNode.parentNode.onclick = (
					event
				) => {
					event.preventDefault();
					const newFilterSection = sections.find(
						(e) =>
							e.title === newRefs[filter.length].parentNode.parentNode.innerText
					);
					setVideoCreds(newFilterSection);
					dispatch(setSubtitle("Notes"));
					localStorage.setItem(
						"topic",
						title
					);
					localStorage.setItem("index", filter.length);
				};
			}
		}
	};
	useEffect(() => {
		sectionTitleProgress(sections);
		openNextSection(tickRef);
	}, [sections, savedProgress]);

	useEffect(() => {

		let isCompleted = false;
		if (student)
			if (sections.length === b && st && !st?.projects) {
				isCompleted = true;
				if (
					isCompleted &&
					+st.Day === student?.SectionTitleProgress&&
						+student?.SectionTitleProgress[
							student?.SectionTitleProgress?.length - 1
						]?.Day +
							1
				) {
					const courseId = selectedCourse?._id;
					// if (language === "Telugu") {
					// 	dispatch(
					// 		updateStudentProgressForDayTelugu({
					// 			courseId: courseId,
					// 			sectionTitleId: st._id,
					// 			Day: st.Day,
					// 			status: true,
					// 			language: language,
					// 		})
					// 	);
					// } else {
					// 	dispatch(
					// 		updateStudentProgressForDay({
					// 			courseId: courseId,
					// 			sectionTitleId: st._id,
					// 			Day: st.Day,
					// 			language: language,
					// 			status: true,
					// 		})
					// 	).then(() => dispatch(getStudents()));
					// }
				}
				 else {
					messageApi.open({
						type: "error",
						content:
							"Go in a order of sections for better understanding of concepts".toUpperCase(),
					});
				}
			}

		return () => {
			if (isCompleted) {
				isCompleted = false;
			}
		};
	}, [b]);

	useEffect(() => {
		if (st?.projects && st?.projects.length) {
			dispatch(getProject(st?.projects));
		}
	}, [st?.projects]);

	useEffect(() => {
		if (st?.projects && st?.projects?.length) {
			let project = projects.find(
				(e) => e.title === localStorage.getItem("topic")
			);
			setLinkTitle([...projects]);
			dispatch(setSubtitle("Notes"));
			setVideoCreds(project);
		} else {
			setLinkTitle(sections);
		}
	}, [sections, st, projects]);


	return (
		<>
			{contextHolder}
			{sectionTitleStatus ? (
				<div
					style={{
						width: "100vw",
						height: "100vh",
						display: "grid",
						placeItems: "center",
					}}
					className={bodystyles.loadingDiv}>
					<Loading />
				</div>
			) : (
				<div className={bodystyles.bodyContainer}>
					<div className={show ? bodystyles.block : bodystyles.null}>
						<button
							className={bodystyles.arrowOut}
							onClick={toggle}>
							<img
								src={arrow}
								className={bodystyles.arroww}
								alt="arrow"
							/>
						</button>
						{/* <Collapse
						accordion={true}
						className={bodystyles.coll}
						> */}
						<section ref={eleRef}>
							{linkTitle ? (
								linkTitle.map((e, i) => {
									
									const unlock =
										st?.projects && projects.length
											? unlockGold
											: savedProgress &&
											  savedProgress.filter((f) =>
													language === "Telugu"
														? f.videoId === e.teluguVideoId
														: f.videoId === e.videoId
											  ).length === 0
											? lockYellow
											: savedProgress &&
											  savedProgress
													.filter((f) =>
														language === "Telugu"
															? f.videoId === e.teluguVideoId
															: f.videoId === e.videoId
													)
													.map((g) => {
														let a = savedProgress.length;

														return g.progress >= 80
															? tick
															: savedProgress[a] !== "" ||
															  savedProgress[a] !== null ||
															  (savedProgress[a] !== undefined &&
																	savedProgress[a - 1].progress >= 80)
															? unlockGold
															: lockYellow;
													});

									return (
										// <Panel
										// 	key={i}
										// 	header={
										// 		<div key={i}>
										// 			<div className={bodystyles.headerSection1}>
										// 				{e.title}
										// 			</div>
										// 		</div>
										// 	}
										// 	className={bodystyles.pan}>
										<button
											className={`${bodystyles.cont} select`}
											disabled={
												studentId === "633d8c8e8f3bcd7e94612929" || studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b"  ? false :
												st?.projects && st?.projects.length
													? false
													: i === 0
													? false
													: `${unlock}` === `${lockYellow}`
													? true
													: false
											}
											style={{
												width: "100%",
												fontSize: "1.2rem",
											}}
											key={i}
											onClick={() => {
												setVideoCreds(e);
												localStorage.setItem("index", i);
												localStorage.setItem("topic", creds.title);
												dispatch(setSubtitle("Notes"));
												dispatch(setTitle(e.title))
											}}>
											<div className={bodystyles.headerSection2}>
												<div className={bodystyles.titles}>{e.title}</div>
												{!st?.projects && (
													<div className={bodystyles.tick}>
														<img
															ref={(el) => (tickRef.current[i] = el)}
															src={
																`${unlock}` === `${unlockGold}`
																	? unlockGold
																	: `${unlock}` === `${lockYellow}`
																	? lockYellow
																	: tick
															}

															alt={e._id}
															width={
																`${unlock}` === `${unlockGold}`
																	? "60%"
																	: `${unlock}` === `${lockYellow}`
																	? "105%"
																	: "80%"
															}
															height={
																`${unlock}` === `${unlockGold}`
																	? "60%"
																	: `${unlock}` === `${lockYellow}`
																	? "105%"
																	: "80%"
															}
														/>
													</div>
												)}
											</div>
										</button>
										// </Panel>
									);
								})
							) : (
								<p
									style={{
										textAlign: "center",
										padding: "0.5rem 0",
										fontSize: "1rem",
										fontWeight: "600",
									}}>
									Loading ...
								</p>
							)}
						</section>

						{/* </Collapse> */}
					</div>
					<div className={bodystyles.bodyDiv2}>
						<Steps
							cred={creds}
							selectSec={selectSec}
							defaultsec={defaultsec}
							setVideoCreds={setVideoCreds}
							show={show}
							disRef={eleRef}
							st={st}
						/>
					</div>
				</div>
			)}
		</>
	);
}
