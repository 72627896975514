// import React from "react";
import axios from "axios";
import { url } from "../graphql";
import { useSelector } from "react-redux";
// import { useNavigate } from "react-router-dom";

export const Payment = async ({ course, student, messageApi }) => {


	// const navigate = useNavigate();
	// const amount = student?.courseEnrolledData?.find((e)=>e.courseId == course._id) ? +course.price / 2 : course.price
	const body = {

		amount: course.price,
		description: course.course_name,
		id: course._id,
		name: student.firstName + " " + student.lastName,
		email: student.email,
	};

	const { data: order } = await axios.post(`https://payment.techiepanda.in/createOrder`, body);
	if (order == "You already erolled this course" ) {
		messageApi.open({
			type: "success",
			content: "You already erolled this course",
		});
		// navigate("/MyDashboard", { replace: true });
		window.location.pathname = "/MyDashboard";
	} else {
		const { data: key } = await axios.get(`https://payment.techiepanda.in/getKey`);
		
		var options = {
			key: key, // Enter the Key ID generated from the Dashboard
			amount: order.amount  * 100, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
			currency: order.curreny,
			courseId: course._id,
			courseName: course.course_name,
			createdAt: new Date().toLocaleString(),
			name: `Techei Panda`,
			description: order.description,
			// "image": "https://example.com/your_logo",
			order_id: order.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
			callback_url: `https://payment.techiepanda.in/verify`,
			prefill: {
				name: student.firstName + " " + student.lastName,
				email: student.email,
				contact: student.phone ? student.phone : null,
			},
			notes: {
				address: "Razorpay Corporate Office",
				courseId: course._id,
				courseName: course.course_name,
				createdAt: new Date().toLocaleString(),
				userId: student._id,
				courseEnrolled: {
					courseName: course.course_name,
					date: new Date().toLocaleString(),
				},
			},
		};

		var rzp1 = new window.Razorpay(options);
		rzp1.open();
	}
};
