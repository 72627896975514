import React, { useEffect, useState } from "react";
import PasswordStyles from "../../styles/profileStyles/passwordStyles.module.scss";
import {
	FilledInput,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { studentprofileUpdate } from "../../redux/slices/student";
// import { compareSync, genSaltSync, hashSync } from "bcryptjs";
import ForgotPassword from "./forgotPasswordComp";
import { forgotPassword, forgotPasswordFun } from "../../redux/slices/studentprofile";

export const Password = () => {


	const [showPassword, setShowPassword] = React.useState(false);
	const [forgot, setForgot] = useState(false);

	const handleClickShowPassword = () => {
		setShowPassword((show) => !show);
	};
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const student = useSelector((state) => state.student.value);

	const [password, setPassword] = useState("");
	const [Cpassword, setCPassword] = useState("");
	// const [Oldpassword, setOldPassword] = useState("");
	const [Output, setOutput] = useState("");

	const dispatch = useDispatch();
	const OnSubmit = () => {
		// const storedPassword = student.password;
		// const compare = compareSync(Oldpassword, storedPassword);

		// if (compare) {
		if (password === Cpassword) {
			// const salt = genSaltSync();
			// const hash = hashSync(password, salt);
			dispatch(forgotPasswordFun({ email:student.email, password: Cpassword })).then(() => {
				console.log("Password Changed");
				setOutput("Password Changed");
				window.location.reload();
			});
		} else {
			setOutput("Password Not Matching");
		}
		// }
		//  else {
		// 	setOutput("Incorrect Password");
		// }
	};
	const toggleForgot = () => {
		if (forgot == false) setForgot(true);
		else setForgot(false);
	};
	return (
		<div className={PasswordStyles.container}>
			<div
				style={
					forgot
						? { display: "none" }
						: {
								display: "block",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
						  }
				}>
				<FormControl
					sx={{ m: 1, width: "60%" }}
					variant="filled">
					<InputLabel htmlFor="filled-adornment-password">
						Old Password
					</InputLabel>
					<FilledInput
						id="filled-adornment-password"
						type={showPassword ? "text" : "password"}
						// onChange={(e) => setOldPassword(e.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
					<div
						style={{
							textAlign: "end",
							color: "blue",
							marginTop: "0.5rem",
							cursor: "pointer",
						}}
						onClick={toggleForgot}>
						Forgot password...?
					</div>
				</FormControl>

				<FormControl
					sx={{ m: 1, width: "60%", marginTop: "2rem" }}
					variant="filled">
					<InputLabel htmlFor="filled-adornment-password">Password</InputLabel>
					<FilledInput
						id="filled-adornment-password"
						type={showPassword ? "text" : "password"}
						onChange={(e) => setPassword(e.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>

				<FormControl
					sx={{ m: 1, width: "60%", marginTop: "2rem" }}
					variant="filled">
					<InputLabel htmlFor="filled-adornment-password">
						Confirm Password
					</InputLabel>
					<FilledInput
						id="filled-adornment-password"
						type={showPassword ? "text" : "password"}
						onChange={(e) => setCPassword(e.target.value)}
						endAdornment={
							<InputAdornment position="end">
								<IconButton
									aria-label="toggle password visibility"
									onClick={handleClickShowPassword}
									onMouseDown={handleMouseDownPassword}
									edge="end">
									{showPassword ? <Visibility /> : <VisibilityOff />}
								</IconButton>
							</InputAdornment>
						}
					/>
				</FormControl>

				<div
					style={
						Output == "Password Not Matching" || Output == "Incorrect Password"
							? {
									color: "red",
									fontSize: "1.2rem",
									marginTop: "1.5rem",
									fontWeight: "600",
							  }
							: {
									color: "green",
									fontSize: "1.2rem",
									fontWeight: "600",
									marginTop: "1.5rem",
							  }
					}>
					{Output}
				</div>

				<button
					className={PasswordStyles.button}
					onClick={OnSubmit}>
					Submit
				</button>
			</div>
			<div
				style={
					!forgot
						? { display: "none" }
						: {
								display: "block",
								width: "100%",
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								height: "100%",
						  }
				}>
				<ForgotPassword />
			</div>
		</div>
	);
};
