import { createSlice } from "@reduxjs/toolkit";

const TitleAndSubTitleSlice = createSlice({
    name : "Title and SubTitle",
    initialState : {
        title : "",
        subTitle : "",
        model : false
    },
    reducers : {
        setTitle : (state, action)=>{
            state.title = action.payload;
        },
        setSubtitle : (state , action)=>{
            state.subTitle = action.payload
        },
        setOpenModel : (state, action)=>{
         state.model = action.payload;
        }
    }
});

export const {setTitle , setSubtitle , setOpenModel} = TitleAndSubTitleSlice.actions;

export default TitleAndSubTitleSlice.reducer;