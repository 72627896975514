import React from "react";
import styles from "../styles/header.module.scss";
import logo from "../icons/techiePandaLogo.png";
import user from "../icons/ProfileIcon.png";
import { Dropdown, Space } from "antd";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message } from "antd";
import { updateStudent } from "../redux/slices/studentprofile";
import { getStudents } from "../redux/slices/student";
import lock from "../icons/lock.png";

export default function Header({ setCoursesClicked }) {
	const student = useSelector((state) => state.student.value);
	const studentPhoto = student.image;
	const location = useLocation();
	const [messageApi, contextHolder] = message.useMessage();
	const selectedCourseId = useSelector((state) => state.course.selectedCourse);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const seelogin = (event) => {
		// event.preventDefault();
		const authToken = localStorage.getItem("token");
		if (
			authToken === null ||
			authToken === "" ||
			authToken === "undefined" ||
			authToken === undefined
		) {
			messageApi.open({
				type: "error",
				content: "Please Login To Enter into Dashboard",
			});
		}
	};

	const logout = (event) => {
		localStorage.clear();
		dispatch(updateStudent());
		navigate("/", { replace: true });
	};

	const items = [
		{
			label: (
				<div>
					{location.pathname == `/students/${localStorage.getItem("id")}` ? (
						<Link
							to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :`/TimeTable/${selectedCourseId && selectedCourseId._id}`}
							className={styles.link}>
							GO TO MAIN PAGE
						</Link>
					) : (
						<Link
							to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :`/students/${localStorage.getItem("id")}`}
							className={styles.link}>
							<div onClick={(e) => seelogin(e)}>PROFILE</div>
						</Link>
					)}
				</div>
			),
			key: "1",
		},
		{
			type: "divider",
		},
		{
			label: (
				<div>
					<Link
						to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :"/MyDashboard"}
						className={styles.link}>
						<div>MY DASHBOARD</div>
					</Link>
				</div>
			),
			key: "2",
		},
		{
			type: "divider",
		},
		{
			label: (
				<div>
					<Link
						to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :"/courses"}
						className={styles.link}>
						<div
							onClick={() => {
								setCoursesClicked(true);
							}}>
							COURSES
						</div>
					</Link>
				</div>
			),
			key: "3",
		},
		// {
		// 	type: "divider",
		// },
		// {
		// 	label: (
		// 		<div>
		// 			<Link
		// 				to="/courses"
		// 				className={styles.link}>
		// 				<div
		// 				// onClick={() => {
		// 				// 	setCoursesClicked(true);
		// 				// 	localStorage.setItem("coursesClicked", true);
		// 				// }}
		// 				>
		// 					PROJECTS REVIEW
		// 				</div>
		// 			</Link>
		// 		</div>
		// 	),
		// 	key: "4",
		// },
		{
			type: "divider",
		},
		{
			label: (
				<div>
					<a
					target="_blank"
						href={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "#" :"https://chat.whatsapp.com/CNXhS9jdflMBPaEcazwNSw"}
						className={styles.link}>
						CONTACT US <img src="https://res.cloudinary.com/cliqtick/image/upload/v1694774705/icons/whatsapp-logo-4454_al521o.png" alt="Whatsapp Icon" width="18px" style={{padding:"0rem 0.2rem"}}/>
					</a>
				</div>
			),
			key: "4",
		},
		{
			type: "divider",
		},
		{
			label: (
				<div>
					<Link
						to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :"/"}
						className={styles.logout}>
						<div onClick={(e) => logout(e)}>LOGOUT</div>
					</Link>
				</div>
			),
			key: "5",
		},
	];

	return (
		<>
			{contextHolder}
			<div className={styles.headerContainer}>
				{selectedCourseId ? (
					<Link to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :`/MyDashboard`}>
						<img
							src={logo}
							width={150}
							onClick={() => dispatch(getStudents())}
						/>
					</Link>
				) : (
					<Link to={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "/" :`/courses`}>
						<img
							src={logo}
							width={150}
						/>
					</Link>
				)}
				<div className={styles.flex}>
					<a target="_blank" href={student?._id == "6513e2a7dc706cf1e7a23b0a" ? "#" :"https://chat.whatsapp.com/CXd0VDMBwrr6I9hGRIgKwQ"} style={{textDecoration:"none",color:"black",cursor:"pointer"}}>

						<button
							className={styles.pause}
							disabled={false}>
								
							Live Mentorship
							<img
								style={{
									width: "20px",
								}}
								src={"https://res.cloudinary.com/cliqtick/image/upload/v1694775396/icons/whatsapp_j6dkzj.png"}
								alt="lock"
							/>
						</button>
					</a>
					<Dropdown
						menu={{
							items,
						}}
						overlayClassName={styles.dropdown}
						trigger={["click"]}>
						<Space>
							<img
								src={studentPhoto ? studentPhoto : user}
								style={{ borderRadius: "50%" }}
								height={50}
								width={50}
							/>
						</Space>
					</Dropdown>
				</div>
			</div>
		</>
	);
}
