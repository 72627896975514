import { BallTriangle } from "react-loader-spinner";

export default () => {
	return (
		<>
			<BallTriangle
				height={100}
				width={100}
				radius={5}
				color="#e39c2f"
				ariaLabel="ball-triangle-loading"
				wrapperClass={{}}
				wrapperStyle=""
				visible={true}
			/>
			
		</>
	);
};
