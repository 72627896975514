import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import TimeTableBody from './timeTableBody'
import ModuleStyles from "../styles/moduleStyles.module.scss"
import { Link, useLocation, useNavigate } from 'react-router-dom'
import imageLink from "../icons//thumbnail3.jpg"
import { moduleTitle } from '../redux/slices/SelectedCourse'
import { getSectionTitle } from '../redux/slices/sectionTite'
import { getQuizProgress } from '../redux/slices/quizProgressSlice'
import { message } from "antd";
import lock from "../icons/lock.png";
import { circularProgressClasses } from '@mui/material'
const ModulePage = ({
    openCal,
	setOpenCalender,
	select,
	setSelected,
	selectedDate,
	setSelectedDate,
}) => {
    const modules = useSelector((state)=>state.module.value)
    const ModuleTitle = useSelector((state)=>state.selectedCourse.title)
	const module = useSelector((state) => state.module.oneModule);
    const student = useSelector((state) => state.student.value);
	const sectionTitle = useSelector((state) => state.SectionTitle.value);
    const studentId = localStorage.getItem("id")

    const [messageApi, contextHolder] = message.useMessage();
    const buttonRef = useRef()
    const butParentRef = useRef()
    
	const location = useLocation();
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [imgg,setImgg] = useState(false)
    
    const selectedCourseId = useSelector(
		(state) => state.course?.selectedCourse?._id
	);

    useEffect(() => {
		if (module && module.length) {
			dispatch(getSectionTitle(module));
		}
   
	}, [module,module?._id]);

	useEffect(() => {
		student && student?.QuizResults
			? dispatch(getQuizProgress(student?.QuizResults))
			: console.log("no student or student.OuizResults");
	}, [student]);


    const setVal = (e,unlock,n,prevUnlocked)=>{
            if(studentId == "6513e2a7dc706cf1e7a23b0a"){
                localStorage.setItem("moduleId",modules[0]._id)
                localStorage.setItem("ModuleTitle",modules[0].title)
                dispatch(getSectionTitle(modules[0]?.Section)).then((data)=>{
                    if(data.payload?.length) navigate(`/TimeTable/${modules[0]._id}`)
                })
            }
       
            if(e._id == modules[0]._id ||unlock && prevUnlocked|| studentId === "633d8c8e8f3bcd7e94612929"|| studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b"){
                dispatch(moduleTitle(e))
                localStorage.setItem("moduleId",e._id)
                localStorage.setItem("ModuleTitle",e.title)
                dispatch(getSectionTitle(e)).then((data)=>{
                    if(data.payload?.length) navigate(`/TimeTable/${e._id}`)
                })
            }else{
                const filtered = modules.indexOf(e)
                messageApi.open({
                    type:"error",
                    content:`Please complete ${modules[filtered <= 0 ? filtered : filtered-1].title} to unlock ${e.title} or wait for ${n >0 ? n :0} more days to unlock this `
                })
            }
           
    }


    return (
        <div className={ModuleStyles.container}>
        {contextHolder}
        { location.pathname == `/Module/${selectedCourseId}` &&
            modules && modules?.map((e,i)=>{
                const startDate = new Date(student?.courseEnrolledData?.find(e => e?.courseId == selectedCourseId)?.courseDate).getTime();
                const currentDate = new Date().getTime() ;
                let totalDays = 0;
                for(let j = 0; j < i ;j ++) {
                    totalDays += modules[j]?.sectionTitle.length
                }
               
                let unlock = false;
                if(totalDays <= parseInt(((currentDate - startDate)/(3600000*24 ))) && student?.SectionTitleProgress?.find((e)=>e.sectionTitleId == modules[i > 0 ? i-1 : i]?.sectionTitle[ modules[i > 0 ? i-1 : i]?.sectionTitle.length-1]) ) unlock = true;
                if(i == 0) unlock = true;



                // const progressBarArr = student?.SectionTitleProgress?.filter(g =>  e?.sectionTitle?.map((h)=>g?.sectionTitleId == h ))
                return(
                    <section className={ModuleStyles.grid} key={i} >

                            <div className={i != modules.length-1 ? ModuleStyles.circle : ModuleStyles.circle1} key={i} >
                                <img src={e?._id == modules[0]?._id || student?.SectionTitleProgress?.find((e)=>e.sectionTitleId == modules[i > 0 ? i-1 : i]?.sectionTitle[ modules[i > 0 ? i-1 : i]?.sectionTitle.length-1] || studentId === "633d8c8e8f3bcd7e94612929"|| studentId === "63328b644297a18e1c7d8081" || studentId === "64032941f22f6593b496ee3b") ? e?.icon : lock} width="30px" />
                            </div>

                        <div  className={ModuleStyles.CardsParent}>
                            <section className={ModuleStyles.CardsBack}>
                            </section>


                            <section className={ModuleStyles.Cards}>

                            {/* style={{borderBottom:`${student?.SectionTitleProgress.filter(g =>  e.sectionTitle.map((h)=>g.sectionTitleId == h))?.length / e.sectionTitle.length*100}%`}} */}
                            <div className={ModuleStyles.titles}>
                                <h1>{e.title}</h1>
                                <p>{e.desc}</p>
                                <section className={ModuleStyles.ButtonParent}>
                                    <span >
                                    
                                    </span>
                                    <button className={ModuleStyles.button} disabled={studentId == "6513e2a7dc706cf1e7a23b0a"&&e._id !== modules[0]?._id ? true :false} onClick={()=>setVal(e,unlock,totalDays-parseInt(((currentDate - startDate)/(3600000*24 ))),student?.SectionTitleProgress?.find((e)=>e.sectionTitleId == modules[i > 0 ? i-1 : i]?.sectionTitle[ modules[i > 0 ? i-1 : i]?.sectionTitle.length-1] ))} 
                                    ref={buttonRef}
                                    >Start</button>

                                </section>  
                            </div>  
                            {/* <div className={ModuleStyles.bar}><div style={ e?.sectionTitle?.map((e)=>{return e})?.includes(progressBarArr&&progressBarArr[0]?.sectionTitleId)?{width:`${progressBarArr?.length / e?.sectionTitle?.length*100}%`,height:"5px",background:"linear-gradient(to Right ,white ,#f4b34a)",bottom:0,position:"absolute"}:{width:"0%",height:"5px",background:"linear-gradient(to Right ,white ,#f4b34a)",bottom:0,position:"absolute"}}></div>  */}
                            
                            {/* <div style={{left: e?.sectionTitle?.map((e)=>{return e})?.includes(progressBarArr&&progressBarArr[0]?.sectionTitleId)?`${progressBarArr?.length / e?.sectionTitle?.length*100}%`: 0,position:"absolute",bottom:0,margin:"auto"}}>{ e?.sectionTitle?.map((e)=>{return e})?.includes(progressBarArr&&progressBarArr[0]?.sectionTitleId)?Math.round(progressBarArr?.length / e?.sectionTitle?.length*100)+"%":0 + "%"}</div> */}
                            
                            {/* </div> */}
                            </section>


                        </div>
                         
                         
                        <section className={ModuleStyles.emptySpace}>

                        </section>
                    </section>
                )
            })
        }
        { location.pathname !== `/Module/${selectedCourseId}` &&  <TimeTableBody
                openCal={openCal}
                setOpenCalender={setOpenCalender}
                select={select}
                setSelected={setSelected}
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}

            />}
        
    </div>
  )
}

export default ModulePage