import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Routes, useLocation, useNavigate, useNavigation } from "react-router-dom";
import "./App.css";
import { getStudents } from "./redux/slices/student";
import { useEffect, useState } from "react";
import { getCourses, getSelectedCourse } from "./redux/slices/course";
import { getModule, getModules } from "./redux/slices/modules";
import Timetable from "./pages/timetable";
import CourseDash from "./pages/courseDash";
import Head from "./pages/header";
import { getSectionTitle } from "./redux/slices/sectionTite";
import Signup from "./pages/sign";
import StudentsProfile from "./pages/studentsProfile";
import { getProgress } from "./redux/slices/videoprogressSlice";
import SubscribedDashboard from "./components/SubscribedDashboard";
import ShowPlans from "./components/studentProfile Components/ShowPlans";
import Certificate from "./components/certificate2";
import { CreateAccount } from "./components/studentProfile Components/createAccount";
import { getQuizProgress } from "./redux/slices/quizProgressSlice";


function App() {
	const token = localStorage.getItem("token")
	const navigate = useNavigate()
	useEffect(()=>{
		if(token && token !== undefined && token !== null && token !== "" ){
			navigate("/MyDashboard",{replace:true})
		}else{
			navigate("/",{replace:true})
		}

	},[token])

	const dispatch = useDispatch();
	const student = useSelector((state) => state.student.value);
	const studentToken = useSelector((state) => state.student.error.token);
	const modules = useSelector((state) => state.module.value);
	const module = useSelector((state) => state.module.oneModule);
	const moduleId = localStorage.getItem("moduleId")
	const selectedCourse = useSelector((state) => state.course.selectedCourse);
	const ModuleTitle = useSelector((state)=>state.selectedCourse.title)


	const [openCal, setOpenCalender] = useState(false);
	const [select, setSelected] = useState();
	const [selectedDate, setSelectedDate] = useState();
	const [showHeader, setHeader] = useState(true);
	const location = useLocation();
	const [coursesClicked, setCoursesClicked] = useState(false);

	function getOS() {
		var userAgent = window.navigator.userAgent,
			platform = window.navigator.platform,
			macosPlatforms = ["Macintosh", "MacIntel", "MacPPC", "Mac68K"],
			windowsPlatforms = ["Win32", "Win64", "Windows", "WinCE"],
			iosPlatforms = ["iPhone", "iPad", "iPod"],
			os = null;

		if (macosPlatforms.indexOf(platform) !== -1) {
			os = "Mac OS";
		} else if (iosPlatforms.indexOf(platform) !== -1) {
			os = "iOS";
		} else if (windowsPlatforms.indexOf(platform) !== -1) {
			os = "Windows";
		} else if (/Android/.test(userAgent)) {
			os = "Android";
		} else if (!os && /Linux/.test(platform)) {
			os = "Linux";
		}

		return { os, userAgent };
	}

	useEffect(() => {
		if (
			location.pathname === "/" ||
			location.pathname === "/createAccount" ||
			location.pathname === "/certificate"
		) {
			setHeader(false);
		} else {
			setHeader(true);
		}
	}, [showHeader, location.pathname]);

	useEffect(() => {
		dispatch(getStudents());
	}, [studentToken]);

	useEffect(() => {
		if (student && student.token) {
			const token = localStorage.token;
			if (token !== student.token) {
				console.log("some one is logged in");
				localStorage.clear();
			}
		}
	}, [student.token]);

	useEffect(() => {
		if (student && student.course && student.course && student.course.length) {
			dispatch(getCourses(student.course));
		}
	}, [student]);

	useEffect(() => {
		if (student && student.progressId && student.progressId.length) {
			dispatch(getProgress({ progressId: student.progressId }));
		}
	}, [student.progressId]);

	const cId = localStorage.getItem("courseId");
	useEffect(() => {
		if (cId) {
			dispatch(getSelectedCourse(cId));
		}
	}, [cId]);

	useEffect(() => {
		if(moduleId){
			dispatch(getModule(moduleId))
		}
		if(!ModuleTitle || ModuleTitle && !ModuleTitle._id){

			if (
				selectedCourse &&
				selectedCourse.modules &&
				selectedCourse.modules.length
			) {
				dispatch(getModules(selectedCourse.modules));
			}
		}else{
			dispatch(getModule(ModuleTitle._id))
		}
	}, [selectedCourse,ModuleTitle._id]);

    useEffect(() => {
		if (module && module._id) {
			dispatch(getSectionTitle(module));
		}
	}, [module,module?._id,moduleId]);
	return (
		<div className="App">
			<div style={showHeader ? { display: "block" } : { display: "none" }}>
				<Head setCoursesClicked={setCoursesClicked} />
			</div>
			<Routes>
				<Route
					path="/"
					element={<Signup getOS={getOS} />}
				/>
				<Route
					path="/Module/:id"
					element={
						<Timetable
							openCal={openCal}
							setOpenCalender={setOpenCalender}
							select={select}
							setSelected={setSelected}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					}
				/>
				<Route
					path="/TimeTable/:id"
					element={
						<Timetable
							openCal={openCal}
							setOpenCalender={setOpenCalender}
							select={select}
							setSelected={setSelected}
							selectedDate={selectedDate}
							setSelectedDate={setSelectedDate}
						/>
					}
				/>
				<Route
					path="/certificate"
					element={<Certificate />}
				/>
				<Route
					path="/dashboard"
					element={<CourseDash />}
				/>
				<Route
					path="/students/:id"
					element={<StudentsProfile />}
				/>
				<Route
					path="/MyDashboard"
					element={
						<SubscribedDashboard
							coursesClicked={coursesClicked}
							setCoursesClicked={setCoursesClicked}
						/>
					}
				/>

				<Route
					path="/courses"
					element={
						<ShowPlans
							coursesClicked={coursesClicked}
							setCoursesClicked={setCoursesClicked}
						/>
					}
				/>

				<Route
					path="/createAccount"
					element={<CreateAccount />}
				/>
			</Routes>
		</div>
	);
}

export default App;
