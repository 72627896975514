import React, { useState } from "react";
import forgotPassword from "../../styles/profileStyles/forgotPassword.module.scss";
import logo from "../../icons/techiePandaLogo.png";
import {
	FilledInput,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	TextField,
} from "@mui/material";
import { MuiOtpInput } from "mui-one-time-password-input";
import { useDispatch } from "react-redux";
import { VerifyOtp, studentprofileUpdate } from "../../redux/slices/student";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { AddOtp, forgotPasswordFun } from "../../redux/slices/studentprofile";

export default function ForgotPassword({ setForgot }) {
	const [Output, setOutput] = useState("");
	const [otpSucc, setOtpSucc] = useState("");
	const [steps, setSteps] = useState("Step1");
	const dispatch = useDispatch();
	const [mail, setMail] = useState("");
	const nextStep = () => {
		if (steps == "Step1") {
			dispatch(
				AddOtp({
					email: mail,
				})
			);
			localStorage.setItem("email", mail);
			setSteps("Step2");
		}
		if (steps == "Step2") {
			dispatch(VerifyOtp({ email: mail, otp: otp })).then((resp) =>
			{

				setOtpSucc(resp.payload.data)

				if (resp.payload.data == "otp is valid") setSteps("Step3");
				else {
					setSteps("Step2");
				}
			}
				);

		}
	};

	const [otp, setOtp] = React.useState("");
	const handleChange = (newValue) => {
		setOtp(newValue);
	};

	const [showPassword, setShowPassword] = React.useState(true);
	const handleClickShowPassword = () => setShowPassword((show) => !show);
	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};

	const [password, setPassword] = useState("");
	const [Cpassword, setCPassword] = useState("");

	const onFinish = () => {
		if (password !== "") setOutput("Password can't be empty");
		if (password.length <= 6) setOutput("Password length should be greater than 6 digits");
		else {
			if (password == Cpassword) {
				dispatch(forgotPasswordFun({ email: mail, password: password })).then(()=>{

					setOutput("Password Changed");
					  window.location.reload()
				})
			} else {
				setOutput("Password Not Matching");
			}
		}
	};

	return (
		<div className={forgotPassword.container}>
			<img
				src={logo}
				alt=""
				className={forgotPassword.logo}
			/>
			<div className={forgotPassword.steps}>
				<div
					className={forgotPassword.step}
					style={
						steps == "Step1" || steps == "Step2" || steps == "Step3"
							? { backgroundColor: "black" }
							: { backgroundColor: "#f4b34a" }
					}></div>
				<hr
					style={{ height: "0%", width: "40%", border: "1px solid #f4b34a" }}
				/>
				<div
					className={forgotPassword.step}
					style={
						steps == "Step2" || steps == "Step3"
							? { backgroundColor: "black" }
							: { backgroundColor: "#f4b34a" }
					}></div>
				<hr
					style={{ height: "0%", width: "40%", border: "1px solid #f4b34a" }}
				/>
				<div
					className={forgotPassword.step}
					style={
						steps == "Step3"
							? { backgroundColor: "black" }
							: { backgroundColor: "#f4b34a" }
					}></div>
			</div>

			<div className={forgotPassword.body}>
				{steps == "Step1" && (
					<div className={forgotPassword.StepsBody}>
						<h3 style={{ marginBottom: "3rem" }}>Enter your email for OTP</h3>
						<TextField
							className={forgotPassword.mailInput}
							id="outlined-password-input"
							label="Email"
							autoComplete="current-password"
							onChange={(e) => setMail(e.target.value)}
							name="Email"
						/>
					</div>
				)}
				{steps == "Step2" && (
					<div className={forgotPassword.StepsBody}>
						<MuiOtpInput
							value={otp}
							onChange={handleChange}
						/>
						<div
							style={
								otpSucc == "otp is valid"
									? { marginTop: "2rem", color: "green" }
									: { marginTop: "2rem", color: "red" }
							}>
							{otpSucc}
						</div>
					</div>
				)}
				{steps == "Step3" && (
					<div className={forgotPassword.StepsBody}>
						<FormControl
							sx={{ m: 1, width: "60%", marginTop: "2rem" }}
							variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Password
							</InputLabel>
							<FilledInput
								id="filled-adornment-password"
								type={showPassword ? "text" : "password"}
								onChange={(e) => setPassword(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>

						<FormControl
							sx={{ m: 1, width: "60%", marginTop: "2rem" }}
							variant="filled">
							<InputLabel htmlFor="filled-adornment-password">
								Confirm Password
							</InputLabel>
							<FilledInput
								id="filled-adornment-password"
								type={showPassword ? "text" : "password"}
								onChange={(e) => setCPassword(e.target.value)}
								endAdornment={
									<InputAdornment position="end">
										<IconButton
											aria-label="toggle password visibility"
											onClick={handleClickShowPassword}
											onMouseDown={handleMouseDownPassword}
											edge="end">
											{showPassword ? <VisibilityOff /> : <Visibility />}
										</IconButton>
									</InputAdornment>
								}
							/>
						</FormControl>
					</div>
				)}
				<div
					style={
						Output !== "Password Changed"
							? { marginTop: "2rem", color: "red" }
							: { marginTop: "2rem", color: "green" }
					}>
					{Output}
				</div>
				<div className={forgotPassword.bottom}>
					<button
						className={forgotPassword.backbutton}
						onClick={() => {
							setForgot(false);
						}}>
						Back To Login
					</button>
					<div onClick={nextStep}>
						{steps == "Step3" ? (
							<span onClick={onFinish}>Change Password</span>
						) : (
							"Next"
						)}
					</div>
				</div>
			</div>
		</div>
	);
}
