import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { url } from "./../../graphql/index";

const CourseSlice = createSlice({
	name: "Courses",
	initialState: {
		value: [],
		Courses: [],
		selectedCourse: {},
	},
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(getCourses.fulfilled, (state, action) => {
			state.value = action.payload;
		});
		builder.addCase(getAllCourses.fulfilled, (state, action) => {
			state.Courses = action.payload;
		});
		builder.addCase(getSelectedCourse.fulfilled, (state, action) => {
			state.selectedCourse = action.payload;
		});
	},
});

export const getCourses = createAsyncThunk("/getCourses", async (args) => {
	try {
		const token = localStorage.getItem("token");

		const ids = args.map((id) => {
			return axios.post(
				`${url}/getOneCourse`,
				{ courseId: id },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);
		});
		const data = await Promise.allSettled(ids);
		return data.map((e) => e.value.data);
	} catch (error) {
		return new Error(error);
	}
});

export const getAllCourses = createAsyncThunk(
	"/getAllCourses",
	async (args) => {
		const token = localStorage.getItem("token");

		try {
			const response = await axios.get(`${url}/getAllCourses`, {
				headers: {
					Authorization: "Bearer " + token,
				},
			});

			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export const getSelectedCourse = createAsyncThunk(
	"/getSelectedCourse",
	async (args) => {
		try {
			const token = localStorage.getItem("token");
			const courseId = localStorage.getItem("courseId");

			const response = await axios.post(
				`${url}/getOneCourse`,
				{ courseId: args ? args : courseId },
				{
					headers: {
						Authorization: "Bearer " + token,
					},
				}
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}
	}
);

export default CourseSlice;
